import {Controller} from "stimulus";

export default class extends Controller {
    connect(){
        $(document).ready(() => {
            EventManager.fire('pin-updated', {
                value: this.age(this.element.value.substring(0, 5))
            })
        });
    }

    update() {
        if (this.element.value.length < 5) {
            return;
        }

        EventManager.fire('pin-updated', {
            value: this.age(this.element.value.substring(0, 5))
        })
    }

    age(days) {
        if (days.length < 5) {
            return 0;
        }

        let baseDate = new Date(1900, 0, 1);
        let dateOfBirth = new Date(baseDate.valueOf());
        let today = new Date();

        dateOfBirth.setDate(dateOfBirth.getDate() + parseInt(days));

        return Math.floor((today - dateOfBirth) / (1000 * 60 * 60 * 24 * 365));
    }
}