import {Controller} from "stimulus";

export default class extends Controller {
    connect(){
        EventManager.listen('pin-updated', data => {
            if (data.value >= this.ageLimit) {
                this.element.classList.remove('d-none');
            } else {
                this.element.classList.add('d-none');
            }
        })
    }

    get ageLimit() {
        return parseInt(this.element.dataset.ageLimit);
    }
}