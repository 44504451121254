import {Controller} from "stimulus";

export default class extends Controller {
    connect(){
        if (this.isSameInitially) {
            this.element.classList.add('d-none');
        } else {
            this.element.classList.remove('d-none');
        }

        EventManager.listen('current-address-is-same-as-permanent', (data) => {
            if (data.isSame) {
                this.element.classList.add('d-none');
            } else {
                this.element.classList.remove('d-none');
            }
        })
    }

    get isSameInitially() {
        return this.element.dataset.isSame === '1';
    }
}