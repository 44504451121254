import {Controller} from "stimulus";

export default class extends Controller {
    connect() {
        EventManager.listen('credit-parameters-changed', data => {
            if (window.location.href.indexOf('application=') != -1) {
                return;
            }

            let [url,] = this.initialActon.split('?'),
                urlParts = url.split('/');

            urlParts.pop();

            let credit = urlParts.pop(),
                newUrl = urlParts.join('/'),
                queryParametersObject = {amount: data.amount, period: data.period, payment: data.payment},
                queryString = $.param(queryParametersObject);

            newUrl = `${newUrl}/${data.slug}/credit-details?${queryString}`;

            this.element.setAttribute('action', newUrl);
        })
    }

    get initialActon() {
        return this.element.getAttribute('action');
    }
}