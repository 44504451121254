import {Controller} from "stimulus"

// All of these are not used anymore. The amounts and periods come from the db.
const smallAmounts = [
    100, 200, 300,
    400, 500, 600, 700, 800,
    900, 1000, 1100, 1200, 1300,
    1400, 1500, 1600, 1700, 1800,
    1900, 2000, 2100, 2200, 2300,
    2400, 2500, 2600, 2700, 2800,
    2900, 3000,
    // 3500, 4000, 4500, 5000
];

const largeAmounts = [
    5000, 7500, 10000, 12500, 15000,
    17500, 20000, 22500, 25000, 27500,
    30000, 32500, 35000, 37500, 40000,
    42500, 45000, 47500, 50000, 52500,
    55000, 57500, 60000, 62500, 65000,
    67500, 70000, 72500, 75000
];

const periods_now = [1];
const periods_small_monthly_100 = [3, 5];
const periods_small_monthly_200 = [3, 5, 7];
const periods_small_monthly_300 = [3, 5, 7, 9];
const periods_small_monthly_300_500 = [3, 5, 7, 9];
const periods_small_monthly_600_900 = [3, 5, 7, 9, 12];
const periods_small_monthly_1000 = [3, 5, 7, 9, 12, 15];
const periods_small_monthly_1100_1500 = [5, 7, 9, 12, 15];
const periods_small_monthly_1600_2000 = [7, 9, 12, 15];
const periods_small_monthly_2100_2500 = [9, 12, 15];
const periods_small_monthly_2600_3000 = [12, 15];


const periods_small_weekly_100 = [7, 11];
const periods_small_weekly_200 = [7, 11, 14];
const periods_small_weekly_300 = [7, 11, 14, 18];
const periods_small_weekly_400 = [7, 11, 14, 18, 22];
const periods_small_weekly_500 = [7, 11, 14, 18, 22, 26];
const periods_small_weekly_600_1000 = [7, 11, 14, 18, 22, 26, 30];
const periods_small_weekly_1100_1500 = [11, 14, 18, 22, 26, 30];
const periods_small_weekly_1600_2000 = [14, 18, 22, 26, 30];
const periods_small_weekly_2100_2500 = [18, 22, 26, 30];
const periods_small_weekly_2600_3000 = [22, 26, 30];

const periods_small_monthly_3000_4500= [12, 15, 18, 21, 24, 27, 30, 33, 36];
const periods_small_monthly_5000= [15, 18, 21, 27, 30, 33];
const periods_small_weekly_3000= [18, 22, 26, 30, 34, 39];
const periods_small_weekly_3500= [22, 26, 30, 34, 39];
const periods_small_weekly_4000= [26, 30, 34, 39];
const periods_small_weekly_4500= [30, 34, 39];
const periods_small_weekly_5000= [34, 39];

// const periods_small_3500= [22, 26, 30, 34, 39];
// const periods_small_4000= [26, 30, 34, 39];
// const periods_small_4500= [30, 34, 39];
// const periods_small_5000= [34, 39];

// var ammounts_optima_5000_20000 = [12, 18, 24, 30, 36]
var periods_large_5000_20000 = [12];

const periods_large_5000_7500 = periods_large_5000_20000.concat([24, 36]);
const periods_large_10000_15000 = periods_large_5000_20000.concat([24, 36, 48]);
const periods_large_17500_20000 = periods_large_5000_20000.concat([24, 36, 48, 60]);
const periods_large_22500_27500 = [12, 24, 36, 48, 60, 72, 84];
const periods_large_30000 = [24, 36, 48, 60, 72, 84];
const periods_large_32500_40000 = [24, 36, 48, 60, 72, 84, 96, 108, 120];
const periods_large_42500_52500 = [36, 48, 60, 72, 84, 96, 108, 120];
const periods_large_55000_65000 = [48, 60, 72, 84, 96, 108, 120];
const periods_large_67500_75000 = [60, 72, 84, 96, 108, 120];

const CONTRIBUTION_TYPES = {
    WEEKLY: 'weekly',
    MONTHLY: 'monthly'
}

export default class extends Controller {
    currAmount = 800;
    currPeriod = 7;
    currContributionType = CONTRIBUTION_TYPES.WEEKLY;
    currAmounts = [];
    currPeriods = [];
    periodSlider = $(".ion-instalment");
    smallAmountsSlider = $(".ion-amount");
    isFastCalculatorShown = 0;

    insuranceAmount = 50;

    calculatorDataSmall = [];
    calculatorDataBig = [];

    static targets = ["currentAmount", "chosenAmount", "currentPeriod", 'currentPeriodText', 'sefLink', 'weeklyContributions', 'monthlyContributions', 'amountValue', 'periodValue', 'contributionTypeValue',
        'currentAmountBig', 'chosenAmountBig', 'amountValueBig', 'totalAmountWithInsurance',
        'fastCalculatorBtn',
        'wantInsuranceCheckbox', 'additionalServiceBox',
        'smallAmountsTab', 'bigAmountsTab', 'smallAmountsTabBtn', 'bigAmountsTabBtn',
        'insuranceCheckbox', 'insuranceAmount', 'insuranceCheckboxLink'
    ];


    connect() {
        let _self = this;

        this.calculatorDataSmall = this.element.dataset.calculatorDataSmall ? JSON.parse(this.element.dataset.calculatorDataSmall) : [];
        this.calculatorDataBig = this.element.dataset.calculatorDataBig ? JSON.parse(this.element.dataset.calculatorDataBig) : [];

        this.currAmount = this.element.dataset.initialAmount ? parseInt(this.element.dataset.initialAmount) : 800;
        this.currPeriod = this.element.dataset.initialPeriod ? parseInt(this.element.dataset.initialPeriod) : 7;
        this.currContributionType = this.element.dataset.initialContributionType ? this.element.dataset.initialContributionType : CONTRIBUTION_TYPES.WEEKLY;

        console.log("this.currAmount", this.currAmount, this.calculatorDataSmall, this.element.dataset.initialAmount ? Object.keys(this.calculatorDataSmall[this.currContributionType]).indexOf(this.element.dataset.initialAmount) : 7)
        this.smallAmountsSlider.ionRangeSlider({
            skin: 'round',
            values: Object.keys(this.calculatorDataSmall[this.currContributionType]), //smallAmounts,
            from: this.element.dataset.initialAmount ? Object.keys(this.calculatorDataSmall[this.currContributionType]).indexOf(this.element.dataset.initialAmount) : 7,
            postfix: " лв.",
            onChange: (data) => this.onAmountChange(data),
            onUpdate: (data) => this.onAmountChange(data),
        });

        this.periodSlider.ionRangeSlider({
            skin: 'round',
            values: this.calculatorDataSmall[this.currContributionType][this.currAmount].periods.weekly,//periods_small_weekly_600_1000, //periods_small_weekly_600_1000,
            from: 0,
            postfix: " вноски",
            onChange: (data) => this.onPeriodChange(data),
            onUpdate: (data) => this.onPeriodChange(data),

        });

        $(".ion-amount-big").ionRangeSlider({
            skin: 'round',
            values: largeAmounts,
            from: 4,
            postfix: " лв.",
            onChange: (data) => this.onAmountBigChange(data),
        });

        this.updateSefLink();

        if (this.element.dataset.initialAmount) {
            this.updateCurrPeriods(parseInt(this.element.dataset.initialAmount));
            this.updatePeriodSlider();
        }

        this.handleAdditionalServiceBox();
        // this.handleActiveTabOnLoad();

        // if(window.location.hash == '#ipoteka') {
        //     this.tabBigAmountsClick();
        // } else {
        //     this.tabSmallAmountsClick();
        // }

        $("#mobileCalculatorSection").on("click", function () {
            let link = _self.element.dataset.calculatorLinkMobile !== '' ? _self.element.dataset.calculatorLinkMobile : null;
            if (link) {
                window.location.href = link;
            }
        });
    }

    onAmountChange(data) {
        // this.currentAmountTargets.innerHTML = data.from_value;
        this.currentAmountTargets.map(t => t.innerHTML = data.from_value);
        this.chosenAmountTargets.map(t => t.innerHTML = data.from_value + " лв.");
        this.amountValueTarget.value = data.from_value;
        if(this.hasTotalAmountWithInsuranceTarget) {
            this.totalAmountWithInsuranceTarget.innerHTML = data.from_value + this.getInsuranceAmountAccordingToAmount(data.from_value);
        }
        if(this.hasInsuranceAmountTarget) {
            this.insuranceAmountTarget.innerHTML = this.getInsuranceAmountAccordingToAmount(data.from_value);
        }

        this.currAmount = parseInt(data.from_value);

        this.handleInsuranceCheckboxLink(data.from_value);
        this.updateCurrPeriods(data.from_value);
        this.updatePeriodSlider();
        this.updateSefLink(this.currAmount, this.currPeriod, this.currContributionType);
        this.checkForDisablingContributionTypes();
    }

    onPeriodChange(data) {
        this.currPeriod = parseInt(data.from_value);

        this.currentPeriodTargets.map(t => t.innerHTML = data.from_value);
        this.periodValueTarget.value = data.from_value;

        this.updateSefLink(this.currAmount, this.currPeriod, this.currContributionType);
    }

    onContributionTypeChange(ev) {
        let newContributionType = ev.target.dataset.contributionType;

        if (this.shouldDisableMonthlyContributions() && newContributionType === CONTRIBUTION_TYPES.MONTHLY) {
            return;
        }
        this.currContributionType = newContributionType;
        this.contributionTypeValueTarget.value = newContributionType;


        this.updateCurrAmounts(newContributionType);
        this.updateAmountsSlider();

        this.updateCurrPeriods(this.currAmount);
        this.updatePeriodSlider();

        if(this.hasCurrentPeriodTextTarget) {
            if(this.currContributionType === CONTRIBUTION_TYPES.WEEKLY) {
                this.currentPeriodTextTarget.innerHTML = 'Брой двуседмични вноски:';
            } else {
                this.currentPeriodTextTarget.innerHTML = 'Брой месечни вноски:';
            }
        }


        this.updateSefLink(this.currAmount, this.currPeriod, this.currContributionType);

    }

    updatePeriodSlider() {
        var instance = this.periodSlider.data("ionRangeSlider");

        if(instance) {
            instance.update({
                values: this.currPeriods,
                from: this.currPeriods.findIndex(i => i == this.currPeriod) ? this.currPeriods.findIndex(i => i == this.currPeriod) : 0
            });
        }
    }

    updateAmountsSlider() {
        var instance = this.smallAmountsSlider.data("ionRangeSlider");

        if (instance) {
            instance.update({
                values: this.currAmounts,
                from: this.currAmounts.findIndex(i => i == this.currAmount) ? this.currAmounts.findIndex(i => i == this.currAmount) : 0
            });
        }
    }

    updateCurrPeriods(currAmount) {
        let data = {};
        data.from_value = currAmount;

        this.currPeriods = this.calculatorDataSmall[this.currContributionType][currAmount].periods[this.currContributionType]
        return;

        if(data.from_value === 100) {
            if (this.currContributionType === CONTRIBUTION_TYPES.WEEKLY) {
                this.currPeriods = periods_small_weekly_100;
            } else if (this.currContributionType === CONTRIBUTION_TYPES.MONTHLY) {
                this.currPeriods = periods_small_monthly_100;
            }
        } else if(data.from_value === 200) {
            if (this.currContributionType === CONTRIBUTION_TYPES.WEEKLY) {
                this.currPeriods = periods_small_weekly_200;
            } else if (this.currContributionType === CONTRIBUTION_TYPES.MONTHLY) {
                this.currPeriods = periods_small_monthly_200;
            }
        } else if(data.from_value === 250 || data.from_value === 350) {
            this.currPeriods = periods_now;
        }
        else if(data.from_value <= 500) {
            if (this.currContributionType === CONTRIBUTION_TYPES.WEEKLY) {
                if(data.from_value === 300) {
                    this.currPeriods = periods_small_weekly_300;
                } else if(data.from_value === 400) {
                    this.currPeriods = periods_small_weekly_400;
                } else if(data.from_value === 500) {
                    this.currPeriods = periods_small_weekly_500;
                }
            } else if (this.currContributionType === CONTRIBUTION_TYPES.MONTHLY) {
                if(data.from_value === 300) {
                    this.currPeriods = periods_small_monthly_300;
                } else {
                    this.currPeriods = periods_small_monthly_300_500;
                }
            }
        }
        else if(data.from_value <= 1000) {
            if (this.currContributionType === CONTRIBUTION_TYPES.WEEKLY) {
                this.currPeriods = periods_small_weekly_600_1000;
            } else if (this.currContributionType === CONTRIBUTION_TYPES.MONTHLY) {
                if(data.from_value < 1000) {
                    this.currPeriods = periods_small_monthly_600_900;
                } else {
                    this.currPeriods = periods_small_monthly_1000;
                }
            }
        }
        else if(data.from_value <= 1500) {
            if (this.currContributionType === CONTRIBUTION_TYPES.WEEKLY) {
                this.currPeriods = periods_small_weekly_1100_1500;
            } else if (this.currContributionType === CONTRIBUTION_TYPES.MONTHLY) {
                this.currPeriods = periods_small_monthly_1100_1500;
            }
        }
        else if(data.from_value <= 2000) {
            if (this.currContributionType === CONTRIBUTION_TYPES.WEEKLY) {
                this.currPeriods = periods_small_weekly_1600_2000;
            } else if (this.currContributionType === CONTRIBUTION_TYPES.MONTHLY) {
                this.currPeriods = periods_small_monthly_1600_2000;
            }
        }
        else if(data.from_value <= 2500) {
            if (this.currContributionType === CONTRIBUTION_TYPES.WEEKLY) {
                this.currPeriods = periods_small_weekly_2100_2500;
            } else if (this.currContributionType === CONTRIBUTION_TYPES.MONTHLY) {
                this.currPeriods = periods_small_monthly_2100_2500;
            }
        }
        else if(data.from_value <= 3000) {
            if (this.currContributionType === CONTRIBUTION_TYPES.WEEKLY) {
                this.currPeriods = periods_small_weekly_2600_3000;
            } else if (this.currContributionType === CONTRIBUTION_TYPES.MONTHLY) {
                this.currPeriods = periods_small_monthly_2600_3000;
            }
        }
        else if (data.from_value <= 5000) {
            // showQuickApproval(1);   TODO

            // setQuickApprovalCheckbox(true);
            // ammounts = ammounts_incredo;

            if (this.currContributionType === CONTRIBUTION_TYPES.WEEKLY) {
                if(data.from_value === 3000) {
                    this.currPeriods = periods_small_weekly_3000;
                } else if(data.from_value === 3500) {
                    this.currPeriods = periods_small_weekly_3500;
                } else if(data.from_value === 4000) {
                    this.currPeriods = periods_small_weekly_4000;
                } else if(data.from_value === 4500) {
                    this.currPeriods = periods_small_weekly_4500;
                } else if(data.from_value === 5000) {
                    this.currPeriods = periods_small_weekly_5000;
                }
            } else if (this.currContributionType === CONTRIBUTION_TYPES.MONTHLY) {
                if(data.from_value === 5000) {
                    this.currPeriods = periods_small_monthly_5000;
                } else {
                    this.currPeriods = periods_small_monthly_3000_4500;
                }
            }


            // if(data.from_value === 3500) {
            //     this.currPeriods = periods_small_3500;
            // } else if(data.from_value === 4000) {
            //     this.currPeriods = periods_small_4000;
            // } else if(data.from_value === 4500) {
            //     this.currPeriods = periods_small_4500;
            // } else if(data.from_value === 5000) {
            //     this.currPeriods = periods_small_5000;
            // }


            // // if(periodVal == 0) {
            // //     // periodVal = ammounts_incredo[0]
            // //     periodVal = ammounts_premium_3500[0]
            // // }
            // // value_sl = ammounts_incredo.indexOf(periodVal)
            // value_sl = ammounts.indexOf(periodVal)
        }


    }

    updateCurrAmounts(currContributionType) {

        this.currAmounts = Object.keys(this.calculatorDataSmall[currContributionType]);

    }

    updateSefLink(amount, period, contributionType) {
        amount = amount || this.currAmount;
        period = period || this.currPeriod;
        contributionType = contributionType || this.currContributionType;

        if(this.hasInsuranceCheckboxTarget && this.insuranceCheckboxTarget.checked) {
            amount += this.getInsuranceAmountAccordingToAmount(amount);
        }

        if(this.hasSefLinkTarget) {
            // this.sefLinkTarget.href = `/download-sef/${amount}/${period}/${contributionType}`;
            this.sefLinkTargets.map(t => {
                t.href = `/download-sef/${amount}/${period}/${contributionType}`;
                t.dataset.link = `/download-sef/${amount}/${period}/${contributionType}`;
            });
        }
    }

    checkForDisablingContributionTypes() {
        if (this.hasMonthlyContributionsTarget) {
            if (this.shouldDisableMonthlyContributions()) {

                this.currContributionType = CONTRIBUTION_TYPES.WEEKLY;
                this.contributionTypeValueTarget.value = CONTRIBUTION_TYPES.WEEKLY;

                this.monthlyContributionsTarget.disabled = true;
                this.monthlyContributionsTarget.classList.remove('active');
                this.monthlyContributionsTarget.style.opacity = 0.5;

                this.weeklyContributionsTarget.classList.add('active');

            } else {
                this.monthlyContributionsTarget.disabled = false;
                this.monthlyContributionsTarget.style.opacity = 1;
            }

            // if (this.shouldDisableWeeklyContributions()) {
            //
            //     this.currContributionType = CONTRIBUTION_TYPES.MONTHLY;
            //     this.contributionTypeValueTarget.value = CONTRIBUTION_TYPES.MONTHLY;
            //
            //     this.weeklyContributionsTarget.disabled = true;
            //     this.weeklyContributionsTarget.classList.remove('active');
            //     this.weeklyContributionsTarget.style.opacity = 0.5;
            //     this.weeklyContributionsTarget.style['pointer-events'] = "none";
            //
            //     this.monthlyContributionsTarget.classList.add('active');
            //
            // } else {
            //     this.weeklyContributionsTarget.disabled = false;
            //     this.weeklyContributionsTarget.style.opacity = 1;
            //     this.weeklyContributionsTarget.style['pointer-events'] = "";
            // }

        }

        this.updateSefLink();
    }

    shouldDisableMonthlyContributions() {
        if (/*this.currAmount > 3000 ||*/ this.currAmount === 250 || this.currAmount === 350) {
            return true;
        }

        return false;
    }

    shouldDisableWeeklyContributions() {
        if(this.currAmount >= 3000) {
            return true;
        }

        return false;
    }

    getPeriodsArrayByAmountAndContributionType(amount, contributionType) {

    }

    onInsuranceCheckboxClick(ev) {
        this.updateSefLink();
    }

    handleInsuranceCheckboxLink(amount) {
        if(this.hasInsuranceCheckboxLinkTarget) {
            if(amount > 800) {
                this.insuranceCheckboxLinkTarget.href = this.element.dataset.insuranceCheckboxLinkMax;
            } else {
                this.insuranceCheckboxLinkTarget.href = this.element.dataset.insuranceCheckboxLink;
            }
        }
    }

    // -------- BIG AMOUNT SLIDER

    onAmountBigChange(data) {
        this.currentAmountBigTarget.innerHTML = data.from_value;
        this.chosenAmountBigTarget.innerHTML = data.from_value + " лв.";
        this.amountValueBigTarget.value = data.from_value;
        this.currAmount = parseInt(data.from_value);
    }

    tabBigAmountsClick() {
        console.log("BIG");

        // window.location.href = "/ipoteka";

        // let calcImgDefault = this.element.dataset.calculatorImgDefault;
        // let calcImg = this.element.dataset.calculatorImgIpoteka !== '' ? this.element.dataset.calculatorImgIpoteka : calcImgDefault;
        // let calcImgMobile = this.element.dataset.calculatorImgIpotekaMobile !== '' ? this.element.dataset.calculatorImgIpotekaMobile : calcImgDefault;
        //
        // $('#desktopCalculatorSection').css("background", "url(" + calcImg  + ")");
        // $('#mobileCalculatorSection').css("background", "url(" + calcImgMobile  + ")");
        //
        // window.location.hash='ipoteka'
        // $('.call-to-action').css('display', 'none');
    }

    tabSmallAmountsClick() {
        console.log("SMALL");
        // window.location.href = "/";

        // let calcImgDefault = this.element.dataset.calculatorImgDefault;
        // let calcImg = this.element.dataset.calculatorImg !== '' ? this.element.dataset.calculatorImg : calcImgDefault;
        // let calcImgMobile = this.element.dataset.calculatorImgMobile !== '' ? this.element.dataset.calculatorImgMobile : calcImgDefault;
        //
        // $('#desktopCalculatorSection').css("background", "url(" + calcImg  + ")");
        // $('#mobileCalculatorSection').css("background", "url(" + calcImgMobile  + ")");
        //
        // history.pushState({}, document.title, location.href.substr(0, location.href.length-location.hash.length));
        // // window.location.hash='small'
        // $('.call-to-action').css('display', 'block');
    }



    // on application/step-one page; Side calculator

    onFastCalculatorClick() {
        $(".term-and-amount .calculator-holder").toggleClass("show")

        if(this.isFastCalculatorShown === 1) {
            this.isFastCalculatorShown = 0;
            this.fastCalculatorBtnTarget.innerHTML = "Промени"

            let data = {
                'amount': this.amountValueTarget.value,
                'period': this.periodValueTarget.value,
                'contributionType': this.contributionTypeValueTarget.value
            };

            $.ajax({
                method: "POST",
                url: "/application/calculator-update-data",
                data: data,
                success(resp) {
                    console.log(" REST ", resp)
                },
                error(error) {
                    console.log(" REST ", error)

                }
            });


        } else {
            this.isFastCalculatorShown = 1;
            this.fastCalculatorBtnTarget.innerHTML = "Запази"
        }
    }


    incrementAmountBtnClick(ev) {
        var instance = this.smallAmountsSlider.data("ionRangeSlider");

        let current = instance.result.from;
        if(current < instance.result.max) {
            current++;
        }

        instance.update({
            // values: smallAmounts,
            from: current
        });
    }

    decrementAmountBtnClick(ev) {
        var instance = this.smallAmountsSlider.data("ionRangeSlider");

        let current = instance.result.from;
        if(current > 0) {
            current--;
        }

        instance.update({
            // values: smallAmounts,
            from: current
        });
    }

    handleAdditionalServiceBox() {
        if(this.hasWantInsuranceCheckboxTarget) {
            $(this.wantInsuranceCheckboxTargets).hover(() => {
                    $(this.additionalServiceBoxTarget).css('display', 'block')
                },
                () => {
                    $(this.additionalServiceBoxTarget).css('display', 'none')
                })


            $(this.wantInsuranceCheckboxTarget).change((e) => {
                if($(this.wantInsuranceCheckboxTarget).is(':checked')) {
                    this.additionalServiceBoxTarget.innerHTML = "<a href=''>Допълнителна услуга срещу такса.</a>";
                } else {
                    this.additionalServiceBoxTarget.innerHTML = "<a href=''>Времето за разглеждане на обикновена заявка за кредит е 10 дни.</a>";
                }
            });
        }
    }

    handleActiveTabOnLoad() {
        let hash = window.location.hash;

        if(hash === "#ipoteka") {
            $(this.smallAmountsTabTarget).removeClass('active show')
            $(this.bigAmountsTabTarget).addClass('active show')

            $(this.smallAmountsTabBtnTarget).removeClass('active')
            $(this.bigAmountsTabBtnTarget).addClass('active')

        } else {
            if(this.hasBigAmountsTabTarget) {
                $(this.bigAmountsTabTarget).removeClass('active show')
                $(this.smallAmountsTabTarget).addClass('active show')

                $(this.bigAmountsTabBtnTarget).removeClass('active')
                $(this.smallAmountsTabBtnTarget).addClass('active')
            }
        }
    }

    getInsuranceAmountAccordingToAmount(amount)
    {
        if(amount > 800) {
            return 100;
        }

        return 50;
    }

}