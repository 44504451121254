import {Controller} from "stimulus";

export default class extends Controller {
    connect(){
        EventManager.listen('credit-parameters-changed', data => {

            if (data.slug === 'pensioner') {
                let asterisk = this.element.querySelector('i');

                if (asterisk !== null) {
                    return;
                }

                asterisk = document.createElement('i');

                asterisk.className = 'fa fa-asterisk fa-required';

                this.element.prepend(asterisk);
            } else {
                let asterisk = this.element.querySelector('i');

                if (asterisk) {
                    this.element.removeChild(asterisk);
                }
            }
        })
    }
}