import {Controller} from "stimulus";

export default class extends Controller {

    linkedInShare(event) {
        window.location = "https://www.linkedin.com/shareArticle?mini=true&url=" + event.currentTarget.dataset.shareUrl + "&title=" + event.currentTarget.dataset.shareContent;
    }

    facebookShare(event) {

        FB.ui({
            display: 'popup',
            method: 'share',
            href: event.currentTarget.dataset.shareUrl,
            quote: event.currentTarget.dataset.shareContent
        });
    }
}
