import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        image: String,
        imageMobile: String,
        color: String
    }

    connect() {
        this.id = this.element.getAttribute('id');

        this.createStyleTag();
    }

    createStyleTag() {
        let styleElement = document.createElement('style');

        let css = document.createTextNode(`
            ${this.imageCSS}
            ${this.imageMobileCSS}
        `);
        styleElement.append(css);

        document.querySelector('head').append(styleElement);
    }

    get imageCSS() {
        if (this.imageValue) {
            return `
            @media(min-width:576px) {
                #${this.id} {
                    background-image: url(${this.imageValue});
                    background-size: cover;
                    background-position: center center;
                    background-repeat: no-repeat;
                }
            }
            `;
        } else {
            return `
            @media(min-width:576px) {
                #${this.id} {
                    background: ${this.color};
                }
            }
            `;
        }
    }

    get imageMobileCSS() {
        if (this.imageMobileValue) {
            return `
            @media(max-width:576px) {
                #${this.id} {
                    background-image: url(${this.imageMobileValue});
                    background-size: cover;
                    background-position: center center;
                    background-repeat: no-repeat;
                }
            }
            `;
        } else {
            return `
            @media(max-width:576px) {
                #${this.id} {
                    background: ${this.color};
                }
            }
            `;
        }
    }

    get color() {
        return this.colorValue || 'none';
    }
}
