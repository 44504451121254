import {Controller} from "stimulus"
import Splide from "../../libs/splide.min";

var startDate = null;
var elapsedTime = 0;
var splide = null;

export default class extends Controller {
    static targets = ['input', 'button', 'surveyForm', 'splideDiv'];

    connect() {
        let that = this;

        this.initProgressBar();

        $(".apply-credit.final form .form-group.checkbox input#apply_terms_03").click(function () {
            $(".apply-credit.final .questionnaire-block").toggleClass("open")
        })

        $("#openSurveyModal").click(() => this.onOpenModal());

        splide = new Splide('.splide', {
            drag: false,
            arrows:false,
            height: "100%",
            perPage: 1,
        });

        var bar = splide.root.querySelector('.my-slider-progress-bar');

        // Updates the bar width whenever the carousel moves:
        splide.on('mounted move', function (ev, newIdenx, prevIndex, destIndex) {
            var end = splide.Components.Controller.getEnd() + 1;
            var rate = Math.min((splide.index + 1) / end, 1);
            bar.style.width = String(100 * rate) + '%';
        });

        splide.mount();

        $(".nextBtn").on("click", (ev) => this.onNextClick(ev));

        $(".prevBtn").on("click", (ev) => this.onBackClick(ev))

    }


    submit() {
        let that = this;

        if(this.validate() == false) return;

        let step = splide.Components.Controller.getEnd() + 1;

        let currStepSelectedInputs = $(`input[data-step='${step}']:checked`);
        console.log(step, currStepSelectedInputs[0]);
        // return;
        let toSend = {
            'surveyId': window.localStorage.getItem('surveyId'),
            'step': step,
            'answers': [
                {
                    'answerId': currStepSelectedInputs[0].value,
                    'answerNum': currStepSelectedInputs[0].dataset.answer_number,
                    'questionNum': step * 2 - 1
                },
                {
                    'answerId': currStepSelectedInputs[1].value,
                    'answerNum': currStepSelectedInputs[1].dataset.answer_number,
                    'questionNum': step * 2
                }
            ]
        }

        $.ajax({
            'method': 'POST',
            "url": "/application-survey/submit",
            'data': JSON.stringify(toSend),
            "success": function (resp) {
                if(resp.success) {

                    // $('#surveyForm').hide();

                    // if(resp.payload && resp.payload.personalType == "introvert") {
                    //     $(".introvert").show();
                    // } else if(resp.payload && resp.payload.personalType == "extrovert") {
                    //     $(".extrovert").show();
                    // }
                    $('#openSurveyModal').addClass('disabledBtn');

                    $('#openSurveyModal').click();

                    window.localStorage.removeItem('surveyId')

                }
            },
            'error': function(resp) {
                console.log("ERRR", resp.responseJSON)
                if(resp.responseJSON.failed) {
                    that.addMsg("error", resp.responseJSON.payload);

                }
            }
        })
    }

    onNextClick(ev) {
        var that = this;

        ev.preventDefault();
        if(this.validate() == true) {
            $(".nextBtn").attr("disabled", true);

            console.log("",splide.Components.Controller.getNext(), splide.Components.Controller.getEnd())

            let step = splide.Components.Controller.getNext();
            if(step === splide.Components.Controller.getEnd()) {
                $(".nextBtn").addClass('disabled');
            }

            let currStepSelectedInputs = $(`input[data-step='${step}']:checked`);
            let toSend = {
                'surveyId': window.localStorage.getItem('surveyId'),
                'step': step,
                'answers': [
                    {
                        'answerId': currStepSelectedInputs[0].value,
                        'answerNum': currStepSelectedInputs[0].dataset.answer_number,
                        'questionNum': step * 2 - 1
                    },
                    {
                        'answerId': currStepSelectedInputs[1].value,
                        'answerNum': currStepSelectedInputs[1].dataset.answer_number,
                        'questionNum': step * 2
                    }
                ]
            }

            $.ajax({
                'method': 'POST',
                "url": "/application-survey/step",
                'data': JSON.stringify(toSend),
                "success": function (resp) {
                    if(resp.success) {
                        $(".nextBtn").attr("disabled", false);

                        splide.Components.Controller.go(splide.Components.Controller.getNext());

                    } else {

                    }
                },
                'error': function(resp) {
                    console.log("ERRR", resp.responseJSON)
                    if(resp.responseJSON.failed) {
                        that.addMsg("error", resp.responseJSON.payload);

                    }
                }
            })



        };
    }

    onBackClick(ev) {
        ev.preventDefault();
        // console.log("",splide.Components.Controller.getNext(), splide.Components.Controller.getEnd())

        $(".nextBtn").removeClass('disabled');

        let prevIndex = splide.Components.Controller.getPrev();
        splide.Components.Controller.go(prevIndex);
    }

    onOpenModal() {
        var that = this;
        startDate = new Date();
        // let surveyId = window.localStorage.getItem('surveyId');

        // if(!surveyId) {
        $.ajax({
            'method': 'POST',
            "url": "/application-survey/init",
            'data': {},
            "success": function (resp) {
                console.log("TUK 1", resp)
                if(resp.success) {
                    if(resp.payload && resp.payload.surveyId) {
                        window.localStorage.setItem('surveyId', resp.payload.surveyId)
                    }
                } else if(resp.failed) {
                    that.addMsg("error", resp.payload);
                }
            },
            'error': function(resp) {
                console.log("TUK 2", resp)

                if(resp.responseJSON.failed) {
                    that.addMsg("error", resp.responseJSON.payload);
                }
            }
        })
        // }
    }

    initProgressBar() {
        if($('.owl-carousel.questionnaire-slider')) {

            $('.owl-carousel.questionnaire-slider').owlCarouselProgressBar({
                size: '10px',
                margin: '20px 50px',
                foregroundColor: 'darkseagreen',
                color: 'green',
                borderRadius: 0,
                transitionInterval: 1,
            });

            $('.owl-carousel.questionnaire-slider').owlCarousel({
                loop:true,
                margin:10,
                nav:true,

                responsive:{
                    0:{
                        items:1
                    },
                    600:{
                        items:3
                    },
                    1000:{
                        items:5
                    }
                },

            })

            $(".questionnaire-slide .form-check label").click(function () {
                // $(this).toggleClass("active");
                $(".questionnaire-slide .form-check input:checked").parent().toggleClass("active")
            })
        }
    }

    validate() {

        let nextIndex = splide.Components.Controller.getNext();  // it's 0 based; so nextIndex is like currStep becuz step is 1 based
        let prevIndex = splide.Components.Controller.getPrev();

        // if (nextIndex < prevIndex) return false;

        if(nextIndex == -1) {
            nextIndex = prevIndex+2;
        }

        let allInputs = document.querySelectorAll(`input[data-step='${nextIndex}']`)
        let checkedInputs = document.querySelectorAll(`input[data-step='${nextIndex}']:checked`)

        let questionsOnThisStep = document.querySelectorAll(".currStepQuestions-" + nextIndex).length

        if (checkedInputs.length == questionsOnThisStep) {
            this.addMsg();
            return true;
        } else {
            this.addMsg("error", "Моля, отговори на всички въпроси");
            return false;
        }
    }

    addMsg(status = null, msg = "") {
        $(".error-container").empty()

        if(status == "error") {
            $(".error-container").append('<div class="alert alert-danger " style="background-color: red; color: white">' + msg + '</div>');
        } else if(status == "success") {
            $(".error-container").append('<div class="alert alert-success ">' + msg + '</div>');
        }
    }





    // NOT USED
    // submitOld() {
    //     let that = this;
    //
    //     if(this.validate() == false) return;
    //
    //     const endDate = new Date();
    //     const spentTime = endDate.getTime() - startDate.getTime();
    //     elapsedTime += spentTime;
    //
    //     let toSend = {
    //         "questions" : []
    //     };
    //     let data = $("#surveyForm").serializeArray()
    //     console.log(data)
    //
    //     for(let i = 0; i < data.length; i++) {
    //         let answerId = data[i].value;
    //         let answerNum = $('#answer'+answerId).data('answer_number');
    //
    //         toSend.questions.push({
    //             questionId: data[i].name.match(/\[(.*?)\]/)[1],   // getting the question id between brackets   "questions[123]" => 123
    //             answerId: data[i].value,
    //             answerNum : answerNum
    //         })
    //
    //         // data[i].value = {
    //         //     'answerId' : answerId,
    //         //     'answerNum' : answerNum
    //         // }
    //     }
    //
    //     // data.push({name: 'startTime', value: Math.round(new Date(startDate).getTime() / 1000)});
    //     // data.push({name: 'endTime', value: Math.round(new Date(endDate).getTime() / 1000)});
    //
    //     toSend.startTime = Math.round(new Date(startDate).getTime() / 1000)
    //     toSend.endTime = Math.round(new Date(endDate).getTime() / 1000)
    //     // data.push({name: 'test', value: {'haha':'123123'}});
    //
    //     // console.log(toSend)
    //     // return
    //
    //     $.ajax({
    //         'method': 'POST',
    //         "url": "/send-application-survey",
    //         'data': JSON.stringify(toSend),
    //         "success": function (resp) {
    //             console.log(resp)
    //
    //             if(resp.success) {
    //                 // that.addMsg("success", resp.payload);
    //
    //                 $('#surveyForm').hide();
    //
    //                 if(resp.payload && resp.payload.personalType == "introvert") {
    //                     $(".introvert").show();
    //                 } else if(resp.payload && resp.payload.personalType == "extrovert") {
    //                     $(".extrovert").show();
    //                 }
    //                 // $("#surveyModal").hide();
    //                 // $('#surveyModal').modal('toggle');
    //                 // $('#openSurveyModal').click();
    //                 $('#openSurveyModal').addClass('disabledBtn');
    //
    //                 console.log("ADDING")
    //             }
    //         },
    //         'error': function(resp) {
    //             console.log("ERRR", resp.responseJSON)
    //             if(resp.responseJSON.failed) {
    //                 that.addMsg("error", resp.responseJSON.payload);
    //
    //             }
    //         }
    //     })
    //
    // }
}
