import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ['offerAmount'];


    connect() {
        let _self = this;

        // $(document).ready(function () {

            const TRANS = JSON.parse(trans);

            jQuery.fn.inputFilter = function (inputFilter) {
                return this.on("input keydown keyup mousedown mouseup select", function () {
                    if (inputFilter(this.value)) {
                        this.oldValue = this.value;
                        this.oldSelectionStart = this.selectionStart;
                        this.oldSelectionEnd = this.selectionEnd;
                    } else if (this.hasOwnProperty("oldValue")) {
                        this.value = this.oldValue;
                        this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
                    } else {
                        this.value = "";
                    }
                });
            };

            jQuery.validator.setDefaults({
                success: "valid",
                ignore: [],
            });

            jQuery.validator.addMethod('cyrRequired', function (value, element) {
                if (/^$|^[а-яА-Я ]+$/.test(value)) {
                    return true;
                } else {
                    return false;
                }
            });


            // LONG FORM
            $("#applyForm").validate({
                validClass: "validField",
                errorClass: "invalidField",
                ignore: [],
                rules: {
                    'application_step_one[firstName]': {
                        required: true,
                        cyrRequired: true
                    },
                    'application_step_one[secondName]': {
                        required: true,
                        cyrRequired: true
                    },
                    'application_step_one[lastName]': {
                        required: true,
                        cyrRequired: true
                    },
                    'application_step_one[email]': {
                        required: true,
                        // Specify that email should be validated
                        // by the built-in "email" rule
                        email: true
                    },
                    'application_step_one[phone]': {
                        required: true,
                        minlength: 10,
                        maxlength: 10
                    },
                    // 'application_step_one[clientNationalId]': {
                    //     required: true,
                    //     minlength: 10,
                    //     maxlength: 10,
                    //     number: true,
                    //     // remote: "/ajax/application_check_realtime"
                    // },
                    'application_step_one[idCardNumber]': {
                        required: true,
                        minlength: 9,
                        maxlength: 9,
                        // number: true,
                        // remote: "/ajax/application_check_realtime"
                    },
                    'application_step_one[idCardCity]': {
                        required: true,
                        // minlength: 2,
                        // remote: "/ajax/application_check_realtime"
                    },

                    paymentMethod: "required",
                    region: "required",
                    municipality: "required",
                    place: "required",
                    street: {
                        required: true,
                    },
                    // // political_person: "required",
                    //
                    //
                    'application_step_two[employmentType]': {
                        required: true,
                        // remote: "/ajax/application_check_realtime"
                    },
                    'application_step_two[monthlyIncome]': {
                        required: true,
                        number: true,

                        // remote: "/ajax/application_check_realtime"
                    },
                    'application_step_two[familyStatus]': {
                        required: true,
                        // remote: "/ajax/application_check_realtime"
                    },
                    'application_step_two[education]': {
                        required: true,
                        // remote: "/ajax/application_check_realtime"
                    },

                    'application_step_three[contactFirstName]': {
                        required: true,
                        cyrRequired: true
                    },
                    'application_step_three[contactLastName]': {
                        required: true,
                        cyrRequired: true
                    },
                    'application_step_three[contactPhone]': {
                        required: true,
                        minlength: 10
                    },
                    'application_step_three[contactConnectionType]': {
                        required: true,
                    },
                    terms: {
                        required: true,
                    },
                    DataUseNOICheckAgreement: {
                        required: true,
                    }
                },
                messages: {
                    'application_step_one[firstName]': TRANS['application.errors.firstName'],//        'Моля, запълни името си на кирилица',
                    'application_step_one[secondName]': TRANS['application.errors.secondName'],//        'Моля, запълни презимето си на кирилица',
                    'application_step_one[lastName]': TRANS['application.errors.lastName'],//           'Моля, запълни фамилията си на кирилица',
                    'application_step_one[email]': TRANS['application.errors.email'],//               'Моля, попълни валиден имейл',
                    'application_step_one[phone]': TRANS['application.errors.phone'],//               'Моля, попълни валиден телефонен номер',
                    // 'application_step_one[clientNationalId]': TRANS['application.errors.clientNationalId'],//  'Моля, попълни валидно ЕГН',
                    'application_step_one[idCardNumber]': TRANS['application.errors.idCardNumber'],//            'Моля, попълни валиден № на ЛК',
                    'application_step_one[idCardCity]': TRANS['application.errors.idCardCity'],//          'Моля, попълни валиден град',
                    'application_step_one[idCardDateOfIssue]': TRANS['application.errors.idCardDateOfIssue'],//  'Моля, попълни дата',
                    'region': TRANS['application.errors.region'],//  'Моля, избери област',
                    'paymentMethod': TRANS['application.errors.paymentMethod'],//  'Полето е задължително',
                    'municipality': TRANS['application.errors.municipality'],//  'Моля, избери община',
                    'place': TRANS['application.errors.place'],//  'Моля, избери населено място',
                    'application_step_one[street]': TRANS['application.errors.street'],//  'Моля, попълни улица',
                    'application_step_two[employmentType]': TRANS['application.errors.employmentType'],//  'Моля, попълни полето',
                    'application_step_two[monthlyIncome]': {
                        required: TRANS['application.errors.monthlyIncome.required'],//  "Моля, попълни полето",
                        number: TRANS['application.errors.monthlyIncome.valid_number'],//  "Моля, попълни валидна стойност",
                    },
                    'application_step_two[familyStatus]': TRANS['application.errors.familyStatus'],//  'Моля, попълни полето',
                    'application_step_two[education]': TRANS['application.errors.education'],//  'Моля, попълни полето',

                    'application_step_three[contactFirstName]': TRANS['application.errors.contactFirstName'],//  "Моля, запълни полето на кирилица",
                    'application_step_three[contactLastName]': TRANS['application.errors.contactLastName'],//  "Моля, запълни полето на кирилица",
                    'application_step_three[contactPhone]': TRANS['application.errors.contactPhone'],//  "Моля, попълни валиден телефонен номер",
                    'application_step_three[contactConnectionType]': TRANS['application.errors.contactConnectionType'],//  "Моля, попълни полето",
                    terms: TRANS['application.errors.terms'],//  "Трябва да се съгласиш с условията",
                    DataUseNOICheckAgreement: TRANS['application.errors.DataUseNOICheckAgreement']//  "Необходимо е съгласие.",
                },
                submitHandler: function (form) {

                    // jQuery("#btn_loading_img").css("display", "block");
                    // jQuery("#submitFormBtn").css("background-color", "#ffcbcd")
                    // jQuery("#submitFormBtn").prop('disabled', true);
                    // jQuery("#applyForm").submit();

                    console.log("SUBMITTING ", form)
                    form.submit();
                },
                invalidHandler: function (event, validator) {
                    // 'this' refers to the form
                    var errorsCount = validator.numberOfInvalids();
                    var errors = validator.errorList;
                    let step = this.dataset['step'];
                    let applicationId = this.dataset['application_id'];
                    let clientId = this.dataset['client_id'];

                    let errorsToSend = {};

                    errors.map(e => {
                        if (e.element.id === "application_step_one_clientNationalId") {
                            errorsToSend['Invalid--EGN'] = "Invalid--EGN";
                        } else if (e.element.id === "application_step_one_idCardNumber") {
                            errorsToSend['Invalid--ID'] = "Invalid--ID";
                        } else if (e.element.id === "application_step_three_contactPhone") {
                            errorsToSend['Invalid--Phone number'] = "Invalid--Phone number";
                        } else if (e.element.id === "apply_terms_03") {
                            errorsToSend['Invalid--Checkbox'] = "Invalid--Checkbox";
                        } else {
                            errorsToSend['Missing fields'] = "Missing fields";
                        }
                    });

                    if (applicationId && clientId) {
                        console.log("SENDING ", {
                            'event': 'VirtualPageView',
                            'Error': errorsToSend,
                            'FormSuccess': 'False',
                            'UX_Element': 'Application Form',
                            'UX_Interaction': 'Step ' + step, // Стъпката, в която се намира
                            'ApplicationID': applicationId, //'323443',
                            'ClientID': clientId, //'166360'
                        });

                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                            'event': 'VirtualPageView',
                            'Error': errorsToSend,
                            //     {
                            //     'Error name': 'Value', // Да се обсъди и създаде таксономия на грешките
                            //     'Error name': 'Value',
                            // },
                            'FormSuccess': 'False',
                            'UX_Element': 'Application Form',
                            'UX_Interaction': 'Step ' + step, // Стъпката, в която се намира
                            'ApplicationID': applicationId, //'323443',
                            'ClientID': clientId, //'166360'
                        });
                    }


                    // myScrollTo(".step-heading");

                    // if (errors) {
                    //     var message = errors == 1
                    //         ? 'You missed 1 field. It has been highlighted'
                    //         : 'You missed ' + errors + ' fields. They have been highlighted';
                    //     $("div.error span").html(message);
                    //     $("div.error").show();
                    // } else {
                    //     $("div.error").hide();
                    // }
                },
                // errorPlacement: function(error, element) {
                //
                //     // if (element.attr("id") == "contact_connection") {
                //     //     $($('#contact_connection').selectize()[0].selectize.$control[0]).addClass("invalidField")
                //     //
                //     // }
                //     // if (element.attr("name") == "terms") {
                //     //     error.insertAfter(element);
                //     // } else {
                //     //     error.insertAfter(element);
                //     // }
                // }
            });

            $("#applyForm input,select").on("input keyup check change", function () {
                $("#submitFormBtn").removeAttr("disabled");
            });

            // LONG FORM
            $("#callMeForm").validate({
                validClass: "validField",
                errorClass: "invalidField",
                ignore: [],
                rules: {
                    'call_me[firstName]': {
                        required: true,
                        cyrRequired: true
                    },
                    'call_me[lastName]': {
                        required: true,
                        cyrRequired: true
                    },
                    'call_me[email]': {
                        required: true,
                        // Specify that email should be validated
                        // by the built-in "email" rule
                        email: true
                    },
                    'call_me[phone]': {
                        required: true,
                        minlength: 10,
                        maxlength: 10
                    },
                    'call_me[clientNationalId]': {
                        required: true,
                        minlength: 10,
                        maxlength: 10,
                        number: true,
                        // remote: "/ajax/application_check_realtime"
                    },
                },
                messages: {
                    'call_me[firstName]': TRANS['application.errors.firstName'],// 'Моля, запълни името си на кирилица',
                    'call_me[lastName]': TRANS['application.errors.lastName'],//'Моля, запълни фамилията си на кирилица',
                    'call_me[email]': TRANS['application.errors.email'],// 'Трябва да попълниш валиден имейл',
                    'call_me[phone]': TRANS['application.errors.phone'],//'Моля, попълни валиден телефонен номер',
                    'call_me[clientNationalId]': TRANS['application.errors.clientNationalId'],//'Трябва да попълниш валидно ЕГН',
                },
                submitHandler: function (form) {
                    form.submit();
                },
                invalidHandler: function (event, validator) {


                },

            });


            $("#callMeForm input,select").on("input keyup check change", function () {
                $("#submitFormBtn").removeAttr("disabled");
            });

            // $(".applyForm").data("validator").settings.submitHandler = function (form) { alert('submit'); form.submit(); };


            $(".filter-only-letters").on("input keydown keyup", function (e) {
                if (/^$|^[а-яА-Яa-zA-Z ]+$/.test(this.value)) {
                    // this.value = _self.latToCyr(this.value);

                    this.oldValue = this.value;
                    this.oldSelectionStart = this.selectionStart;
                    this.oldSelectionEnd = this.selectionEnd;
                } else if (this.hasOwnProperty("oldValue")) {
                    this.value = this.oldValue;
                    this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
                } else {
                    this.value = "";
                }
            });


            $(".filter-only-digits").inputFilter(function (value) {
                return /^\d*$/.test(value);    // Allow digits only, using a RegExp
            });


            // Initialize form validation on the registration form.
            // It has the name attribute "registration"
            // $("form[class='requestFormSmall']").validate({
            $("#bigAmountsForm").validate({
                validClass: "validField",
                errorClass: "invalidField",
                rules: {
                    // The key name on the left side is the name attribute of an input field. Validation rules are defined on the right side
                    'calculator_big_amounts[propertyLocation]': "required",
                    'calculator_big_amounts[firstName]': {
                        required: true,
                        cyrRequired: true
                    },
                    'calculator_big_amounts[secondName]': {
                        required: true,
                        cyrRequired: true
                    },
                    'calculator_big_amounts[lastName]': {
                        required: true,
                        cyrRequired: true
                    },
                    'calculator_big_amounts[email]': {
                        required: true,
                        // Specify that email should be validated
                        // by the built-in "email" rule
                        email: true
                    },
                    'calculator_big_amounts[phone]': {
                        required: true,
                        minlength: 10,
                        maxlength: 10
                    },
                    'terms': "required",
                },
                // Specify validation error messages
                messages: {
                    'calculator_big_amounts[propertyLocation]': TRANS['application.errors.propertyLocation'],//"Полето е задължително",
                    'calculator_big_amounts[firstName]': TRANS['application.errors.firstName'],//"Моля, попълни име на кирилица",
                    'calculator_big_amounts[secondName]': TRANS['application.errors.secondName'],//"Моля, попълни презиме на кирилица",
                    'calculator_big_amounts[lastName]': TRANS['application.errors.lastName'],//"Моля, попълни фамилия на кирилица",
                    'calculator_big_amounts[phone]': {
                        required: TRANS['application.errors.phone'],//"Моля, попълни телефон",
                        minlength: TRANS['application.errors.phone.minLength'],//"Телефонът трябва да бъде 10 цифри",
                        maxlength: TRANS['application.errors.phone.maxLength'],//"Телефонът трябва да бъде 10 цифри",
                    },
                    'calculator_big_amounts[email]': TRANS['application.errors.email'],//"Моля, попълни валиден имейл",
                    'terms': TRANS['application.errors.terms'],//"Полето е задължително",
                },
                // Make sure the form is submitted to the destination defined
                // in the "action" attribute of the form when valid
                submitHandler: function (form) {
                    form.submit();
                }
            });

            $("#bigAmountsForm input,select").on("input keyup check change", function () {
                $("#submitBigFormBtn").removeAttr("disabled");
            });

            $("#smallAmountsForm").validate({
                validClass: "validField",
                errorClass: "invalidField",
                rules: {
                    // The key name on the left side is the name attribute of an input field. Validation rules are defined on the right side
                    'calculator_small_amounts[firstName]': {
                        required: true,
                        cyrRequired: true
                    },
                    'calculator_small_amounts[secondName]': {
                        required: true,
                        cyrRequired: true
                    },
                    'calculator_small_amounts[lastName]': {
                        required: true,
                        cyrRequired: true
                    },
                    'calculator_small_amounts[email]': {
                        required: true,
                        // Specify that email should be validated
                        // by the built-in "email" rule
                        email: true
                    },
                    'calculator_small_amounts[phone]': {
                        required: true,
                        minlength: 10,
                        maxlength: 10
                    },
                    'calculator_small_amounts[clientNationalId]': {
                        required: true,
                        minlength: 10,
                        maxlength: 10,
                        number: true,
                        // remote: "/ajax/application_check_realtime"
                    },
                    'terms': "required",
                },
                // Specify validation error messages
                messages: {
                    'calculator_small_amounts[firstName]': TRANS['application.errors.firstName'],//"Моля, попълни име на кирилица",
                    'calculator_small_amounts[secondName]': TRANS['application.errors.secondName'],//"Моля, попълни презиме на кирилица",
                    'calculator_small_amounts[lastName]': TRANS['application.errors.lastName'],//"Моля, попълни фамилия на кирилица",
                    'calculator_small_amounts[clientNationalId]': TRANS['application.errors.clientNationalId'],//"Моля, попълни фамилия на кирилица",
                    'calculator_small_amounts[phone]': {
                        required: TRANS['application.errors.phone'],//"Моля, попълни телефон",
                        minlength: TRANS['application.errors.phone.minLength'],//"Телефонът трябва да бъде 10 цифри",
                        maxlength: TRANS['application.errors.phone.maxLength'],//"Телефонът трябва да бъде 10 цифри",
                    },
                    'calculator_small_amounts[email]': TRANS['application.errors.email'],//"Моля, попълни валиден имейл",
                    'terms': TRANS['application.errors.terms'],//"Полето е задължително",
                },
                // Make sure the form is submitted to the destination defined
                // in the "action" attribute of the form when valid
                submitHandler: function (form) {
                    form.submit();
                }
            });

            $("#smallAmountsForm input,select").on("input keyup check change", function () {
                $(".submitFormBtn").removeAttr("disabled");
            });
            // $(".requestFormSmall input").on("keyup", function(){
            //     //     if($(".requestFormSmall").valid())
            //     //     {
            //     $(".submitBtn").removeAttr("disabled");
            //     //     }
            // });

        // })

    }

    insuranceCheckboxClick(ev)
    {
        let checked = ev.target.checked;

        if(ev.target.type == 'radio') {
            checked = ev.target.value == 1 ? true : false;
        }
        document.querySelector('#confirmInsurance-input').checked = checked;
        document.querySelector('#wantsInsurance-input').checked = checked;
        document.querySelector('#otpSigningAgreement').checked = checked;

        if(checked) {
            document.querySelector('#wantsHealthInsuranceYes').checked = 1;
            document.querySelector('#wantsTreatmentInsuranceYes').checked = 1;

            document.querySelector('#insuranceTypeDefault').checked = 1;
            document.querySelector('#insuranceTypeDefault').disabled = 0;
            document.querySelector('#insuranceTypeMax').disabled = 0;
            document.querySelector('#insuranceTypeElit').disabled = 0;

        } else {
            document.querySelector('#wantsHealthInsuranceNo').checked = 1;
            document.querySelector('#wantsTreatmentInsuranceNo').checked = 1;

            document.querySelector('#insuranceTypeDefault').checked = 0;
            document.querySelector('#insuranceTypeMax').checked = 0;
            document.querySelector('#insuranceTypeElit').checked = 0;
            document.querySelector('#insuranceTypeDefault').disabled = 1;
            document.querySelector('#insuranceTypeMax').disabled = 1;
            document.querySelector('#insuranceTypeElit').disabled = 1;
        }

        let currAmount = this.element.dataset.amount;

        let insuranceInput = document.querySelector("input[name='insuranceAmount']:checked");
        let insuranceAmount = insuranceInput ? insuranceInput.value : 0;

        if(checked) {
            this.offerAmountTarget.innerHTML = parseInt(currAmount) + parseInt(insuranceAmount);
        } else {
            this.offerAmountTarget.innerHTML = this.element.dataset.amount;
        }
    }

    insuranceTypeClick(ev) {
        let currInsuranceAmount = ev.target.value;
        let currAmount = this.element.dataset.amount;

        this.offerAmountTarget.innerHTML = parseInt(currAmount) + parseInt(currInsuranceAmount);
    }

    latToCyr(word) {
        // var a = {"Ё":"YO","Й":"I","Ц":"TS","У":"U","К":"K","Е":"E","Н":"N","Г":"G","Ш":"SH","Щ":"SCH","З":"Z","Х":"H","Ъ":"'","ё":"yo","й":"i","ц":"ts","у":"u","к":"k","е":"e","н":"n","г":"g","ш":"sh","щ":"sch","з":"z","х":"h","ъ":"'","Ф":"F","Ы":"I","В":"V","А":"a","П":"P","Р":"R","О":"O","Л":"L","Д":"D","Ж":"ZH","Э":"E","ф":"f","ы":"i","в":"v","а":"a","п":"p","р":"r","о":"o","л":"l","д":"d","ж":"zh","э":"e","Я":"Ya","Ч":"CH","С":"S","М":"M","И":"I","Т":"T","Ь":"'","Б":"B","Ю":"YU","я":"ya","ч":"ch","с":"s","м":"m","и":"i","т":"t","ь":"'","б":"b","ю":"yu"};
        var a = {
            "A": "А",
            "B": "Б",
            "W": "В",
            "G": "Г",
            "D": "Д",
            "E": "Е",
            "V": "Ж",
            "Z": "З",
            "I": "И",
            "J": "Й",
            "K": "К",
            "L": "Л",
            "M": "М",
            "N": "Н",
            "O": "О",
            "P": "П",
            "R": "Р",
            "S": "С",
            "T": "Т",
            "U": "У",
            "F": "Ф",
            "H": "Х",
            "C": "Ц",
            "~": "Ч",
            "{": "Ш",
            "}": "Щ",
            "X": "ь",
            "|": "Ю",
            "Q": "Я",
            "Y": "Ъ",
            "a": "а",
            "b": "б",
            "w": "в",
            "g": "г",
            "d": "д",
            "e": "е",
            "v": "ж",
            "z": "з",
            "i": "и",
            "j": "й",
            "k": "к",
            "l": "л",
            "m": "м",
            "n": "н",
            "o": "о",
            "p": "п",
            "r": "р",
            "s": "с",
            "t": "т",
            "u": "у",
            "f": "ф",
            "h": "х",
            "c": "ц",
            "`": "ч",
            "[": "ш",
            "]": "щ",
            "": "ь",
            "\\": "ю",
            "q": "я",
            "y": "ъ"
        };

        return word.split('').map(function (char) {
            return a[char] || char;
        }).join("");
    }
}