import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["email", "flashMessage"];


    subscribe(event) {
        var email = this.emailTarget.value;
        var url = "/bulletin-subscribe-ajax";

        var flashMessage = this.flashMessageTarget;

        event.preventDefault();

        $.ajax({
            method: 'POST',
            url: url,
            data: {'email': email},
            success(response) {
                flashMessage.classList.add("alert-success");
                flashMessage.innerHTML = response.payload.message;
            },
            error: function (error) {
                var err = JSON.parse(error.responseText);
                flashMessage.classList.add("alert-danger");
                flashMessage.innerHTML = err.payload.message;
            }
        });

        flashMessage.classList.remove("alert-danger");
        this.emailTarget.value = "";

    }


}
