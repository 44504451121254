import {Controller} from "stimulus";

export default class extends Controller {
    static values = {
        partText: String,
        text: String
    }

    static targets = ['textContainer']

    showedAll = false;

    collapse(e) {
        if (this.showedAll) {
            this.textContainerTarget.innerHTML = this.partTextValue;
            this.showedAll = false;
            e.target.innerHTML = 'Покажи повече';
        } else {
            this.textContainerTarget.innerHTML = this.textValue;
            this.showedAll = true;
            e.target.innerHTML = 'Скрий';
        }
    }
}