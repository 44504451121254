import {Controller} from "stimulus";

export default class extends Controller {
    connect(){
        EventManager.listen('master-field-changed', data => {
            if (data.propertyName === this.propertyName) {
                if (data.value === this.propertyValue) {
                    this.element.classList.remove('d-none');
                } else {
                    this.element.classList.add('d-none');
                }
            }
        })
    }

    get propertyName() {
        return this.element.dataset.propertyName;
    }

    get propertyValue() {
        return this.element.dataset.propertyValue;
    }
}