import {Controller} from "stimulus";

export default class extends Controller {
    change() {
        let label = this.element.parentNode.querySelector('.custom-file-label'),
            fileNames = [];

        this.element.files.forEach(file => {
           fileNames.push(file.name);
        });

        let fileNamesString = fileNames.join(', ');

        label.innerHTML = "<span>" + fileNamesString + "</span>";
    }
}