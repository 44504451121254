
export function initializeTestimonialVideosCarousel() {
    $('.video-carousel.owl-carousel').owlCarousel({
        loop:true,
        margin:10,
        nav:true,
        lazyLoad: true,
        responsive:{
            0:{
                items:1
            },
            575:{
                items:2,
                margin: 15
            },
            768:{
                items:2,
                margin: 30
            },
            1000:{
                items:3,
                margin: 30
            }
        }
    })
}

$(document).ready(function () {

    $(window).scroll(function () {
        if ($(this).scrollTop() >= 300) {
            $('header').addClass('fixed');


            if ($(this).scrollTop() < 400) {
                $('header').removeClass('fixed-block');

            }

            if ($(this).scrollTop() >= 400) {
                $('header').addClass('fixed-block');
            }

            if ($(this).scrollTop() >= 600) {
                $('header').addClass('fixed-show');
                $('.call-to-action').addClass('show');
            }

            if ($(this).scrollTop() < 600) {
                $('header').removeClass('fixed-show');
                $('.call-to-action').removeClass('show');
            }

        } else {
            $('header').removeClass('fixed');
            $('.call-to-action').removeClass('show');

        }
    });

    $(document).ready(function () {
        $(".credits .owl-credits .item").click(function () {
            var element = document.getElementById('text-slider');
            $('html, body').animate({
                scrollTop: (getOffset2(element).top - 350)
            }, 1000);
        });

        // $(".calculator-holder .nav-amounts-sec a").click(function () {
        //     $(".calculator-holder .nav-amounts-sec a").removeClass("active");
        //     $(this).addClass("active")
        // })
        //
        // $("#Component_34_4").click(function () {
        //     $("#nav-profile2").removeClass("show active")
        //     $("#nav-home2").addClass("show active")
        // })
        //
        // $("#Component_34").click(function () {
        //     $("#nav-home2").removeClass("show active")
        //     $("#nav-profile2").addClass("show active")
        // })


        /***
         * PROMO POP-UP BANNER
         */
        if ($('#promoPopupBanner').length) {
            let bannerNoShowPages =['nps'];

            let path = window.location.pathname;
            if (bannerNoShowPages.some(function(v) { return path.indexOf(v) >= 0; })) {
                return;
            }

            $('#promoPopupBanner').modal({'backdrop': 'static'});
            $('#promoPopupBanner').modal('show');

            $("#closePromoPopupBannerBtn").on('click', (e) => {
                const date = new Date();
                date.setDate(date.getDate() + 1);
                const expires = "expires=" + date.toUTCString();
                document.cookie = "hide_promotions_banner=1; " + expires + "; path=/;";
            });
        }

    });

    function getOffset(el) {
        el = el.getBoundingClientRect();
        return {
            left: el.left + window.scrollX,
            top: el.top + window.scrollY
        }
    }

    function getOffset2(el) {
        var _x = 0;
        var _y = 0;
        while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
            _x += el.offsetLeft - el.scrollLeft;
            _y += el.offsetTop - el.scrollTop;
            el = el.offsetParent;
        }
        return {top: _y, left: _x};
    }



    // MOVED TO google_maps_controller.js

    // $('.js-example-basic-single').select2({
    //     minimumResultsForSearch: -1,
    //     dropdownCssClass: "myFont"
    // });


    // $("header .nav-btn").click(function () {
    //     $(".nav-btn").toggleClass("open")
    //     $("header .mobile-nav").toggleClass("active")
    // })

    // $(".ion-amount").ionRangeSlider({
    //     skin: 'round',
    //     min: 1000,
    //     max: 5000,
    //     from: 1500,
    //     postfix: " лв."
    // });
    //
    // $(".ion-instalment").ionRangeSlider({
    //     skin: 'round',
    //     min: 2,
    //     max: 30,
    //     from: 15,
    //     postfix: " вноски"
    // });

    var creditsSlider = $('.owl-credits');
    var creditsTextSlider = $('.owl-creditsText');

    if (creditsSlider && creditsSlider.length != 0) {
        creditsSlider.owlCarousel({
            autoplay: false,
            center: false,
            loop: true,
            touchDrag: true,
            nav: true,
            dots: false,
            responsiveClass: true,
            items: 4,
            URLhashListener: true,
            responsive: {
                0: {
                    items: 1,

                },
                992: {
                    items: 2,
                },
                1200: {
                    items: 4,
                },
                1700: {
                    items: 4,
                }
            }
        })
    }

    if (creditsTextSlider && creditsTextSlider.length != 0) {
        creditsTextSlider.owlCarousel({
            autoplay: false,
            center: false,
            loop: true,
            nav: false,
            dots: false,
            responsiveClass: true,
            items: 1,
            URLhashListener: true,
            responsive: {
                0: {},
                600: {},
                1000: {}
            }
        })
    }

    // $('.owl-credits .owl-item').click(function(){
    //     $(this).addClass("first");
    // });

    function creditsSliderClasses() {
        creditsSlider.each(function () {
            var total = $(this).find('.owl-item.active').length;
            $(this).find('.owl-item').removeClass('first');
            $(this).find('.owl-item').removeClass('last');
            $(this).find('.owl-item.active').each(function (index) {
                if (index === 0) {
                    $(this).addClass('first')
                }
                if (index === total - 1 && total > 1) {
                    $(this).addClass('last')
                }
            })
        })
    }

    creditsSliderClasses();
    creditsSlider.on('translated.owl.carousel', function (event) {
        creditsSliderClasses()
    });

    $(".creditBox").on("click", function (ev) {
        creditsTextSlider.trigger('to.owl.carousel', [this.dataset.to, 500]);
        ;
    });

//    button show/hide calculator

    // $(".apply-credit .term-and-amount .term-and-amount-info button").click(function () {
    //     $(".term-and-amount .calculator-holder").toggleClass("show")
    // })


//    choose file
    $('#chooseFile').bind('change', function () {
        var filename = $("#chooseFile").val();
        if (/^\s*$/.test(filename)) {
            $(".file-upload").removeClass('active');
            $("#noFile").text("CV");
        }
        else {
            $(".file-upload").addClass('active');
            $("#noFile").text(filename.replace("C:\\fakepath\\", ""));
        }
    });


//    blog slider

    if ($('.blog .owl-carousel') && $('.blog .owl-carousel').length != 0) {

        $('.blog .owl-carousel').owlCarousel({
            loop: true,

            nav: true,
            responsive: {
                0: {
                    items: 1,
                    margin: 20,
                    stagePadding: 20
                },
                340: {
                    items: 1,
                    margin: 20,
                    stagePadding: 30
                },
                376: {
                    items: 1,
                    margin: 20,
                    stagePadding: 60
                },
                575: {
                    margin: 30,
                    items: 2
                },
                992: {
                    items: 3,
                    margin: 30
                }
            }
        })
    }

    $('.testimonial-slider .owl-carousel').owlCarousel({
        loop: true,
        margin: 40,
        nav: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            768: {
                items: 2
            }
        }
    })

    initializeTestimonialVideosCarousel();

    //nps rate icons

    if ($(".service-rating .rate-block .rate-icons .form-group label")) {
        $(".service-rating .rate-block .rate-icons .form-group label").click(function () {
            $(".service-rating .rate-block .rate-icons .form-group label").removeClass("active");
            $(this).addClass("active");
        })
    }


    //    canvas-timer

    (function () {

        function clear(ctx) {
            ctx.clearRect(0, 0, 80, 80);
        }

        function setTrack(ctx) {
            ctx.strokeStyle = 'hsla(2, 8%, 46%, 0.45)';
            ctx.lineWidth = 4;
            ctx.beginPath();
            ctx.arc(36, 36, 27, 0, Math.PI * 2);
            ctx.stroke();
        }

        function setTime(ctx, until, now, total) {
            ctx.strokeStyle = 'hsl(2, 8%, 46%)';
            ctx.lineWidth = 8;
            ctx.beginPath();
            ctx.arc(
                36,
                36,
                27,
                Math.PI / -2,
                (Math.PI * 2) * ((until - now % total) / total) + (Math.PI / -2),
                false
            );
            ctx.stroke();
        }

        function numberOfDays(year, month) {
            var d = new Date(year, month, 0);
            return d.getDate();
        }

        if (document.getElementById('months')) {

            var mo = document.getElementById('months').getContext('2d'),
                d = document.getElementById('days').getContext('2d'),
                h = document.getElementById('hours').getContext('2d'),
                mi = document.getElementById('minutes').getContext('2d'),
                s = document.getElementById('seconds').getContext('2d'),
                ms = document.getElementById('milliseconds').getContext('2d'),
                grad = new Date(2015, 3, 16, 17, 0, 0, 0, 0),
                monthDays = {
                    cache: {},
                    getTotalDaysInMonth: function (year, month) {
                        if (!this.cache[year]) {
                            this.cache[year] = {};
                        }
                        if (!this.cache[year][month]) {
                            this.cache[year][month] = (new Date(year, month + 1, 0)).getDate();
                        }
                        return this.cache[year][month];
                    }
                };

            function set() {

                var today = new Date(),
                    daysThisMonth = monthDays.getTotalDaysInMonth(today.getFullYear(), today.getMonth());

                clear(mo);
                setTrack(mo);
                setTime(mo, grad.getMonth(), today.getMonth(), 12);

                clear(d);
                setTrack(d);
                setTime(d, grad.getDate(), today.getDate(), daysThisMonth);

                clear(h);
                setTrack(h);
                setTime(h, grad.getHours(), today.getHours(), 24);

                clear(mi);
                setTrack(mi);
                setTime(mi, grad.getMinutes(), today.getMinutes(), 60);

                clear(s);
                setTrack(s);
                setTime(s, grad.getSeconds(), today.getSeconds(), 60);

                clear(ms);
                setTrack(ms);
                setTime(ms, grad.getMilliseconds(), today.getMilliseconds(), 1000);

                requestAnimationFrame(set);

            }

            requestAnimationFrame(set);
        }

    })();


    if ($("#applyForm") && $("#applyForm").length != 0) {

        $("#applyForm").validate({
            validClass: "validField",
            errorClass: "invalidField",
            ignore: [],
            rules: {
                // application_step_one_firstName: {
                //     required: true,
                //
                // },
                // egn: {
                //     required: true,
                //     minlength: 10,
                //     maxlength:10,
                //     number: true,
                //     remote: "/ajax/application_check_realtime"
                // },
                // id_number: {
                //     required: true,
                //     minlength: 9,
                //     maxlength:9,
                //     number: true,
                //     remote: "/ajax/application_check_realtime"
                // },
                // authority: {
                //     required: true,
                //     // minlength: 2,
                //     // remote: "/ajax/application_check_realtime"
                // },
                // id_issued_day: {
                //     required: true,
                //     min: 1,
                //     max: 31,
                // },
                // id_issued_month: {
                //     required: true,
                //     min: 1,
                //     max: 12,
                // },
                // id_issued_year: {
                //     required: true,
                //     min: 1950,
                //     max: 2050,
                // },
                // payment_method: "required",
                // area: "required",
                // community: "required",
                // population_area: "required",
                // street: {
                //     required: true,
                // },
                // // political_person: "required",
                //
                //
                // employment_type: {
                //     required: true,
                //     remote: "/ajax/application_check_realtime"
                // },
                // monthly_income: {
                //     required: true,
                //     remote: "/ajax/application_check_realtime"
                // },
                // marital_status: {
                //     required: true,
                //     remote: "/ajax/application_check_realtime"
                // },
                // education: {
                //     required: true,
                //     remote: "/ajax/application_check_realtime"
                // },
                //
                // contact_first_name: {
                //     required: true,
                // },
                // contact_last_name: {
                //     required: true,
                // },
                // contact_phone: {
                //     required: true,
                //     minlength: 10
                // },
                // contact_connection: {
                //     required: true,
                // },
                // terms: {
                //     required: true,
                // }
            },
            messages: {
                egn: "Трябва да попълниш валидно ЕГН",
                id_number: "Трябва да попълниш валиден номер на ЛК",
                authority: "Трябва да попълниш полето",
                id_issued: "Трябва да попълниш полето",
                id_issued_day: {
                    required: "Трябва да попълниш полето",
                    min: "Попълни валидна стойност",
                    max: "Попълни валидна стойност",
                },
                id_issued_month: {
                    required: "Трябва да попълниш полето",
                    min: "Попълни валидна стойност",
                    max: "Попълни валидна стойност",
                },
                id_issued_year: {
                    required: "Трябва да попълниш полето",
                    min: "Попълни валидна стойност",
                    max: "Попълни валидна стойност",
                },
                payment_method: "Трябва да попълниш полето",
                area: "Трябва да попълниш полето",
                community: "",
                population_area: "",
                street: "Въведи улица",

                employment_type: "Трябва да попълниш полето",
                monthly_income: "Трябва да попълниш полето",
                marital_status: "Трябва да попълниш полето",
                education: "Трябва да попълниш полето",
                contact_first_name: "Трябва да попълниш полето",
                contact_last_name: "Трябва да попълниш полето",
                contact_phone: "Трябва да попълниш полето",
                contact_connection: "Трябва да попълниш полето",
                terms: "Трябва да се съгласиш с условията"
            },
            submitHandler: function (form) {

                console.log("SUBMIT")
                // jQuery("#btn_loading_img").css("display", "block");
                // jQuery("#submitFormBtn").css("background-color", "#ffcbcd")
                // jQuery("#submitFormBtn").prop('disabled', true);
                // // jQuery("#applyForm").submit();

                console.log("SUBMITTING ", form)
                // form.submit();
            },
            invalidHandler: function (event, validator) {
                // 'this' refers to the form
                var errors = validator.numberOfInvalids();

                console.log("INVALID " + errors)
                // myScrollTo(".step-heading");

                // if (errors) {
                //     var message = errors == 1
                //         ? 'You missed 1 field. It has been highlighted'
                //         : 'You missed ' + errors + ' fields. They have been highlighted';
                //     $("div.error span").html(message);
                //     $("div.error").show();
                // } else {
                //     $("div.error").hide();
                // }
            },
            errorPlacement: function (error, element) {

                // if (element.attr("id") == "contact_connection") {
                //     $($('#contact_connection').selectize()[0].selectize.$control[0]).addClass("invalidField")
                //
                // }
                // if (element.attr("name") == "terms") {
                //     error.insertAfter(element);
                // } else {
                //     error.insertAfter(element);
                // }
            }
        });

    }


    // Career PAGE file upload

    $(document).on('change', '.up', function () {
        var names = [];
        var length = $(this).get(0).files.length;
        for (var i = 0; i < $(this).get(0).files.length; ++i) {
            names.push($(this).get(0).files[i].name);
        }
        // $("input[name=file]").val(names);
        if (length > 2) {
            var fileName = names.join(', ');
            document.querySelector('#fileName').value = length + " files selected"
            // $(this).closest('.form-group').find('.form-control').attr("value",length+" files selected");
        }
        else {
            // console.log(document.querySelector('.file-upload-text.'+this.dataset.type), '.file-upload-text.'+this.dataset.type)
            document.querySelector('.file-upload-text.' + this.dataset.type).value = names
        }

        $('#fileRemove.' + this.dataset.type).show();
        $('#fileUpload.' + this.dataset.type).hide();

    });

    $('#fileRemove').click(() => {
        $("#up").val(null);
        document.querySelector('#fileName').value = ''
        $('#fileRemove.cv').hide();
        $('#fileUpload.cv').show();
    });

    $('.fileRemovePortfolio').click(() => {
        $("#portfolio").val(null);
        // document.querySelector('#fileName').value = ''
        document.querySelector('.file-upload-text.portfolio').value = '';
        $('#fileRemove.portfolio').hide();
        $('#fileUpload.portfolio').show();
    })


    // CAREERS LIST PAGE SHOW ALL CAREERS
    $("#showAllCareersBtn").click(() => {
        console.log("SHOWING")
        $('.career-row').show();
        $('.show-all-careers-row').hide();
    })

});

//scroll to apply form


$(document).ready(function () {

    $('section .ck-content table').wrap('<div class="table-responsive"></div>');

    $(".customer-service .apply-btn a").click(function () {
        var element = document.getElementById('apply');
        $('html, body').animate({
            scrollTop: (getOffset2(element).top - 90)
        }, 1000);
    });

    $(".google-map .how-to-find .form-group select").click(function () {
        $(".gm-style .gm-style-iw-a").parent().addClass("loc-block")
    })

    $(".gm-style .gm-style-iw-a").parent().css("width", "100%")


});

function getOffset(el) {
    el = el.getBoundingClientRect();
    return {
        left: el.left + window.scrollX,
        top: el.top + window.scrollY
    }
}

function getOffset2(el) {
    var _x = 0;
    var _y = 0;
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
        _x += el.offsetLeft - el.scrollLeft;
        _y += el.offsetTop - el.scrollTop;
        el = el.offsetParent;
    }
    return {top: _y, left: _x};
}

//start video modal
$(document).ready(function() {

// Gets the video src from the data-src on each button
    var $videoSrc;
    $('.video-btn').click(function() {
        $videoSrc = $(this).data( "src" );
    });

    // when the modal is opened autoplay it
    $('#myModal').on('shown.bs.modal', function (e) {

        // set the video src to autoplay and not to show related video. Youtube related video is like a box of chocolates... you never know what you're gonna get
        $("#video").attr('src',$videoSrc + "?rel=0&amp;autoplay=1&amp;modestbranding=1&amp;showinfo=0&amp;controls=1&amp;zoom=1&amp;");
    })

    // stop playing the youtube video when I close the modal
    $('#myModal').on('hide.bs.modal', function (e) {
        // a poor man's stop video
        $("#video").attr('src',$videoSrc);
    })


    $(".icheck-success.credit-insurance label, .icheck-success.credit-insurance input").click(function () {
        if ($('.icheck-success.credit-insurance input').is(':checked')) {
            $("#nav-profile2 .insurance-info").addClass("open");
            $("#nav-profile2 .calculator-total").addClass("mt-md-5")
        }
        else {
            $("#nav-profile2 .insurance-info").removeClass("open");
            $("#nav-profile2 .calculator-total").removeClass("mt-md-5")
        }
    })

    $('.clever-link').click(function(){
        window.open($(this).data('link'), $(this).data('target') ? $(this).data('target') : "_self");
        return false;
    });
// document ready
});
