import {Controller} from "stimulus"

export default class extends Controller {
    switch(event) {
        let url = `/en/admin/locale/${this.url}`;

        event.preventDefault();

        $.ajax({
            method: 'POST',
            url: url,
            success() {
                window.location.href = window.location.href;
            },
            error(error) {
                console.log(error);
            }
        });
    }

    get url() {
        return this.data.get('locale');
    }
}