import {Controller} from "stimulus";

export default class extends Controller {

    static targets = ["map", "lat", "lng", "icon", "imagePath", "officesForPlace", "officeView", "placeInfo", "siteUrl", "offices", "office"];

    connect() {
        $('#placeSelect').select2({
            minimumResultsForSearch: -1,
            dropdownCssClass: "myFont"
        });

        $('#officeSelect').select2({
            minimumResultsForSearch: -1,
            dropdownCssClass: "myFont"
        });

        $('#placeSelect').on('select2:select', (e) => {
            this.handlePlaceSelectors(e.params.data.id, $(e.currentTarget).find(":selected").data('place_slug'))
        });

        $('#officeSelect').on('select2:select', (e) => this.selectOffice(e));


        this.handlePlaceSelectors(this.element.dataset.curr_place_id)

    }

    handlePlaceSelectors(placeId = null, placeSlug = null) {

        var _self = this;

        placeId = placeId || 0; // this.placeInfoTarget.value;
        var officeAp = $('#officeSelect')[0]; //this.officesForPlaceTarget;

        if(!officeAp) return;

        // var imagePath = this.imagePathTarget.dataset.image_path;
        var displayedOffices = $("#offices"); // this.officesTarget;
        var contactBlogs = $("#contact-blogs");

        if(placeSlug != null) {
            window.location.href = '/contact/' + placeSlug;
            return;
        }

        if (placeId == 0) {

            $.ajax({
                type: "POST",
                url: "/offices/getAllOffices",
                success: function (result) {

                    // contactBlogs.empty();

                    var selectedOffices = result.payload.offices;

                    // contactBlogs.append(result.payload.officesForPlace);

                    officeAp.innerHTML = "";
                    displayedOffices.innerHTML = "";

                    var location = [];

                    var map = new google.maps.Map(document.getElementById("map"), {
                        center: new google.maps.LatLng(42.695537, 23.2999071),
                        zoom: 12,
                    });

                    var infowindow = new google.maps.InfoWindow({
                        minWidth: 300,
                        maxWidth: 400,
                        minHeight: 300,
                        maxHeight: 400
                    });

                    var marker, i;

                    selectedOffices.map(office => {

                        var workTime = office.workTime;
                        var weekWorkHourFrom = office.weekWorkTimeFrom;
                        var weekWorkHourTo = office.weekWorkTimeTo;
                        var address = office.address;
                        var path = "/contact/" + office.placeSlug + "/" + office.slug;

                        var saturdayWorkTime = office.saturdayWorkHourFrom != "00:00" ? "Събота: " + office.saturdayWorkHourFrom + " - " + office.saturdayWorkHourTo : "";
                        var officeInfo = "Адрес: " + address + "</br>" + " Работно време:  " + workTime + ": " + weekWorkHourFrom + " -" + weekWorkHourTo + "<br>" + saturdayWorkTime + "<br>" +'<a href="' + path + '"> Виж офис </a>';

                        var icon = {
                            url: _self.imagePathTarget.dataset.google_map_icon,
                            scaledSize: new google.maps.Size(50, 50),
                            origin: new google.maps.Point(0, 0)
                        }

                        location = {
                            lat: office.lat,
                            lng: office.lng,
                            icon: icon
                        }


                        marker = new google.maps.Marker({
                            position: new google.maps.LatLng(location.lat, location.lng),
                            icon: icon,
                            map: map
                        });

                        google.maps.event.addListener(marker, 'click', (function (marker) {
                            return function () {
                                infowindow.setContent(officeInfo);
                                infowindow.open(map, marker);
                                // map.setZoom(12);
                            }
                        })(marker, i));
                    });
                }
            });


        } else {

            $.ajax({
                type: "POST",
                url: "/offices/getOfficesByPlace/" + placeId,
                success: function (result) {

                    contactBlogs.empty();

                    var selectedOffices = result.payload.offices;

                    officeAp.innerHTML = "";
                    displayedOffices.innerHTML = "";

                    displayedOffices.append(result.payload.officesHTML)

                    contactBlogs.append(result.payload.officesForPlace);

                    var option = new Option("Избери офис", 0);
                    option.setAttribute("data-lat", selectedOffices[0].lat);
                    option.setAttribute("data-lng", selectedOffices[0].lng);

                    officeAp.append(option);

                    selectedOffices.map(office => {
                        var option = new Option(office.address, office.address);
                        option.setAttribute("data-lat", office.lat);
                        option.setAttribute("data-lng", office.lng);
                        option.setAttribute("data-icon", office.icon);
                        option.setAttribute("data-weekHourFrom", office.weekWorkTimeFrom);
                        option.setAttribute("data-weekHourTo", office.weekWorkTimeTo);
                        option.setAttribute("data-saturdayWorkHourFrom", office.saturdayWorkHourFrom);
                        option.setAttribute("data-saturdayWorkHourTo", office.saturdayWorkHourTo);
                        option.setAttribute("data-sundayWorkHourFrom", office.sundayWorkHourFrom);
                        option.setAttribute("data-sundayWorkHourTo", office.sundayWorkHourTo);
                        option.setAttribute("data-workTime", office.workTime);
                        option.setAttribute("data-address", office.address);
                        option.setAttribute("data-slug", office.slug);
                        option.setAttribute("data-place_slug", office.placeSlug);
                        officeAp.append(option);
                    })

                    if (selectedOffices.length === 1) {
                        officeAp.setAttribute("data-mapZoom", 18);
                    } else {
                        officeAp.setAttribute("data-mapZoom", 12);
                    }

                    var mapZoom = officeAp.dataset.mapzoom;
                    if (mapZoom === "undefined" || isNaN(mapZoom)) {
                        mapZoom = 10;
                    }

                    var lat = officeAp.options[officeAp.selectedIndex].dataset.lat;
                    var lng = officeAp.options[officeAp.selectedIndex].dataset.lng;

                    var location = [];

                    var map = new google.maps.Map(document.getElementById("map"), {
                        center: new google.maps.LatLng(lat, lng),
                        zoom: parseInt(mapZoom),
                    });

                    var infowindow = new google.maps.InfoWindow({
                        minWidth: 300,
                        maxWidth: 400,
                        minHeight: 300,
                        maxHeight: 400
                    });

                    var marker, i;

                    selectedOffices.map(office => {

                        var workTime = office.workTime;
                        var weekWorkHourFrom = office.weekWorkTimeFrom;
                        var weekWorkHourTo = office.weekWorkTimeTo;
                        var address = office.address;
                        var path = "/contact/" + office.placeSlug + "/" + office.slug;


                        var saturdayWorkTime = office.saturdayWorkHourFrom != "00:00" ? "Събота: " + office.saturdayWorkHourFrom + " - " + office.saturdayWorkHourTo : "";
                        var officeInfo = "Адрес: " + address + "</br>" + " Работно време:  " + workTime + ": " + weekWorkHourFrom + " -" + weekWorkHourTo + "<br>" + saturdayWorkTime + "<br>" +'<a href="' + path + '"> Виж офис </a>';

                        var icon = {
                            // url: imagePath + "viva-viva-logo.svg",
                            url: _self.imagePathTarget.dataset.google_map_icon,
                            scaledSize: new google.maps.Size(50, 50),
                            origin: new google.maps.Point(0, 0)
                        }


                        location = {
                            lat: office.lat,
                            lng: office.lng,
                            icon: icon
                        }


                        marker = new google.maps.Marker({
                            position: new google.maps.LatLng(location.lat, location.lng),
                            icon: icon,
                            map: map
                        });

                        google.maps.event.addListener(marker, 'click', (function (marker) {
                            return function () {
                                infowindow.setContent(officeInfo);
                                infowindow.open(map, marker);
                                // map.setZoom(12);
                            }
                        })(marker, i));
                    });
                }
            });
        }
    }

    selectOffice(e) {

        var _self = this;

        // var officeAp = this.officesForPlaceTarget;
        // var imagePath = this.imagePathTarget.dataset.image_path;
        var officeAp = e.currentTarget; //$('#officeSelect')[0]; //this.officesForPlaceTarget;

        if(officeAp.options[officeAp.selectedIndex].value == 0) return;
        // $(officeAp).on("change", function () {


        var workTime = officeAp.options[officeAp.selectedIndex].dataset.worktime;
        var workHourFrom = officeAp.options[officeAp.selectedIndex].dataset.weekhourfrom;
        var workHourTill = officeAp.options[officeAp.selectedIndex].dataset.weekhourto;
        var saturdayWorkHourFrom = officeAp.options[officeAp.selectedIndex].dataset.saturdayworkhourfrom;
        var saturdayWorkHourTo = officeAp.options[officeAp.selectedIndex].dataset.saturdayworkhourto;
        var address = officeAp.options[officeAp.selectedIndex].dataset.address;
        var path = "/contact/" + officeAp.options[officeAp.selectedIndex].dataset.place_slug + "/" + officeAp.options[officeAp.selectedIndex].dataset.slug;

        var saturdayWorkTime = saturdayWorkHourFrom != "00:00" ? "Събота: " + saturdayWorkHourFrom + " - " + saturdayWorkHourTo : "";
        var officeInfo = "Адрес: " + address + "</br>" + " Работно време:  " + workTime + ": " + workHourFrom + " -" + workHourTill + "<br>" + saturdayWorkTime + "<br>" +'<a href="' + path + '"> Виж офис </a>';


        var infowindow = new google.maps.InfoWindow({
            minWidth: 400,
            maxWidth: 500,
            minHeight: 400,
            maxHeight: 500
        });

        var marker;
        var lat = officeAp.options[officeAp.selectedIndex].dataset.lat;
        var lng = officeAp.options[officeAp.selectedIndex].dataset.lng;

        var map = new google.maps.Map(document.getElementById("map"), {
            center: new google.maps.LatLng(lat, lng),
            zoom: parseInt(18),
        });

        var icon = {
            url: _self.imagePathTarget.dataset.google_map_icon, //imagePath + "viva-viva-logo.svg",
            scaledSize: new google.maps.Size(50, 50),
            origin: new google.maps.Point(0, 0)
        }

        marker = new google.maps.Marker({
            position: new google.maps.LatLng(lat, lng),
            icon: icon,
            map: map
        });

        infowindow.setContent(officeInfo);
        infowindow.open(map, marker);
        map.setZoom(18);

        // });

    }

    initMap() {
        var _self = this;


        var lat = parseFloat(this.officeViewTarget.dataset.lat);
        var lng = parseFloat(this.officeViewTarget.dataset.lng);
        var address = this.officeViewTarget.dataset.address;
        var workDayFrom = this.officeViewtarget.dataset.workDayFrom;
        var workDayTo = this.officeViewtarget.dataset.workDayTill;
        var workHourFrom = this.officeViewTarget.dataset.workhourfrom.date;
        var workHourTill = this.officeViewTarget.dataset.workhourtill;

        var imagePath = this.imagePathTarget.dataset.image_path;



        var officeInfo = "Адрес: " + address + "Работно време: " + workDayFrom + " - " + workDayTo + ":" + workHourFrom + " -" + workHourTill;


        var map, marker;

        map = new google.maps.Map(document.getElementById("map"), {
            center: {lat: lat, lng: lng},
            zoom: 16,
        });

        var infowindow = new google.maps.InfoWindow({
            minWidth: 400,
            maxWidth: 500,
            minHeight: 400,
            maxHeight: 500
        });


        var icon = {
            url: _self.imagePathTarget.dataset.google_map_icon, // imagePath + 'viva-viva-logo.svg',
            scaledSize: new maps.Size(100, 100),
            origin: new google.maps.Point(0, 0)
        }

        marker = new google.maps.Marker({
            position: new google.maps.LatLng(lat, lng),
            icon: icon,
            map: map
        });

        google.maps.event.addListener(marker, 'click', (function (marker) {
            return function () {
                infowindow.setContent(officeInfo);
                infowindow.open(map, marker);
            }
        })(marker));
    }

    officeView() {

        var _self = this;

        var office = JSON.parse(this.officeTarget.dataset.office);
        var imagePath = this.imagePathTarget.dataset.image_path;

        var infowindow = new google.maps.InfoWindow({
            minWidth: 100,
            maxWidth: 300,
            minHeight: 100,
            maxHeight: 300
        });
        var weekWorkTimeFrom = new Date(office.weekWorkTimeFrom.date).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit'});
        var weekWorkTimeTo = new Date(office.weekWorkTimeTo.date).toLocaleTimeString('en-GB', {  hour: '2-digit', minute: '2-digit'});
        var saturdayWorkTimeFrom = new Date(office.saturdayWorkHourFrom.date).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit'});
        var saturdayWorkTimeTo = new Date(office.saturdayWorkHourTo.date).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit'});
        var address = office.address;

        var saturdayWorkTime = saturdayWorkTimeFrom != "00:00" ? "Събота: " + saturdayWorkTimeFrom + " - " + saturdayWorkTimeTo : "";
        var officeInfo = "Адрес: " + address  + "</br>" + "Работно време: " + office.workTime + "</br>" + weekWorkTimeFrom + " - " + weekWorkTimeTo + "</br>" + saturdayWorkTime;

        var lat = parseFloat(office.lat);
        var lng = parseFloat(office.lng);

        var map, marker;

        map = new google.maps.Map(document.getElementById("map"), {
            center: {lat: lat, lng: lng},
            zoom: 16,
        });

        // var icon = {
        //     url: _self.imagePathTarget.dataset.google_map_icon ? _self.imagePathTarget.dataset.google_map_icon : "", // imagePath + "viva-viva-logo.svg",
        //     scaledSize: new google.maps.Size(50, 50),
        //     origin: new google.maps.Point(0, 0)
        // }

        marker = new google.maps.Marker({
            position: new google.maps.LatLng(lat, lng),
            // icon: icon,
            map: map
        });

        google.maps.event.addListener(marker, 'click', (function (marker) {
            return function () {
                infowindow.setContent(officeInfo);
                infowindow.open(map, marker);
            }
        })(marker));


    }


}



