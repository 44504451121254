import {Controller} from "stimulus";

export default class extends Controller {

    static targets = ['bankAccountInfo', 'epayInfo'];

    connect(){

    }

    onMethodChange(ev) {
        // console.log('ev' , ev.target)

        if(ev.target.dataset.methodName == 'bank') {
            this.bankAccountInfoTarget.style.display = 'block'
        } else {
            this.bankAccountInfoTarget.style.display = 'none'
        }

        // if(ev.target.dataset.methodName == 'epay') {
        //     this.epayInfoTarget.style.display = 'block'
        // } else {
        //     this.epayInfoTarget.style.display = 'none'
        // }
    }

}