import {Controller} from "stimulus";

export default class extends Controller {
    connect(){
        this.radioButtons = this.element.querySelectorAll('input');

        this.radioButtons.forEach(element => {
            element.addEventListener('change', this.toggle);
        });
    }

    toggle() {
        EventManager.fire('current-address-is-same-as-permanent', {
            isSame: this.value === '1'
        });
    }
}