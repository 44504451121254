import {Controller} from "stimulus"

export default class extends Controller {
    connect() {

        setTimeout(() => {  // temporary solution
            let apiKey = this.element.getAttribute('data-api-key');
            let capthaInput = this.element;
            grecaptcha.ready(function () {
                grecaptcha.execute(apiKey, {action: 'homepage'}
                ).then(function (token) {
                    capthaInput.value = token;
                })
            });
        }, 1000)

    }
}