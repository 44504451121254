import {Controller} from "stimulus";

export default class extends Controller {
    connect(){
        EventManager.listen('credit-parameters-changed', data => {
            this.element.innerHTML = data[this.propertyName];
        })
    }

    get propertyName () {
        return this.element.dataset.propertyName;
    }
}