import {Controller} from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
    connect() {
        let _self = this;
        let rows = this.element.children;

        Sortable.create(this.element, {
            ghostClass: 'blue-background-during-drag',
            animation: 300,
            onSort: function () {

                rows.forEach((element, index) => {

                    let inputs = element.querySelectorAll('input');

                    inputs.forEach((e, i) => {
                        e.setAttribute('id', _self.updateId(e.getAttribute('id'), index));
                        e.setAttribute('name', _self.updateName(e.getAttribute('name'), index));
                    })
                })
            }
        });
    }

    updateName(currentName, index) {

        return currentName.replace(/\[\d+\]/, `[${index}]`);
    }

    updateId(currentId, index) {

        return currentId.replace(/_\d+_/, `_${index}_`);
    }
}