import {Controller} from "stimulus";

export default class extends Controller {

    static targets = ['region', 'municipality', 'place', 'addressByIdContainer', 'isCurrentAddressDifferentCheckbox', 'regionById', 'municipalityById', 'placeById'];

    connect() {
        console.log("Regions connect")
        this.isCurrentAddressDifferentCheckboxTarget.addEventListener('change', (ev) => {
            if(ev.target.checked) {
                this.addressByIdContainerTarget.style.display = 'block';
            } else {
                this.addressByIdContainerTarget.style.display = 'none';
            }
        })


        this.getMunicipalitiesByRegion(this.selectedRegionId)

        this.getMunicipalitiesByRegionById(this.selectedRegionByIdId)



        // console.log("DAAA", this.selectedRegionByIdId)
        // var regionSelectize = $('.region_selector').selectize({
            // valueField: 'id',
            // labelField: 'name',
            // create: false,
            // sortField: 'name',
            // searchField: ['name'],
        // });

    }

    onRegionChange(ev) {
        this.getMunicipalitiesByRegion(this.selectedRegionId)
    }

    onMunicipalityChange() {
        this.getPlacesByMunicipality(this.selectedMunicipalityId);
    }

    getMunicipalitiesByRegion(regionId) {
        let that = this;

        if(!regionId) {
            return;
        }

        $.ajax({
            type: "GET",
            url: "/ekatte/municipalities-by-region/" + regionId,
            success: function (data) {

                if (data.success) {
                    that.updateMunicipalityDropdown(data.payload);

                    let munId = Object.values(data.payload)[0].id;
                    let prevSelectedMunicipalityId = that.targets.find("municipality").dataset.municipalityId;   // при грешка напр и рефреш на страницата да сетне предишно избраната община
                    if(prevSelectedMunicipalityId) {
                        munId = prevSelectedMunicipalityId;
                        that.targets.find("municipality").dataset.municipalityId = "";
                    }
                    that.getPlacesByMunicipality(munId)
                }
            }
        })
    }

    getPlacesByMunicipality(municipalityId) {
        let that = this;

        $.ajax({
            type: "GET",
            url: "/ekatte/places-by-municipality/" + municipalityId,
            success: function (data) {
                if (data.success) {
                    that.updatePlaceDropdown(data.payload);
                }
            }
        })
    }

    updateMunicipalityDropdown(data) {

        this.municipalityTarget.innerHTML = "";

        Object.values(data).forEach((m) => {
            const option = document.createElement("option");
            option.value = m.id;
            option.innerHTML = m.name;

            let prevSelectedMunicipalityId = this.targets.find("municipality").dataset.municipalityId;   // при грешка напр и рефреш на страницата да сетне предишно избраната община
            if(prevSelectedMunicipalityId && prevSelectedMunicipalityId == m.id) {
                option.selected = true;
                // this.targets.find("municipality").dataset.municipalityId = "";
            }

            this.municipalityTarget.appendChild(option);
        });

    }

    updatePlaceDropdown(data) {
        this.placeTarget.innerHTML = "";

        Object.values(data).forEach((m) => {
            const option = document.createElement("option");
            option.value = m.id;
            option.innerHTML = m.name;

            let prevSelectedPlaceId = this.targets.find("place").dataset.placeId;   // при грешка напр и рефреш на страницата да сетне предишно избраната община
            // console.log("PLACE ", prevSelectedPlaceId)
            if(prevSelectedPlaceId && prevSelectedPlaceId == m.id) {
                option.selected = true;
                // this.targets.find("place").dataset.placeId = "";
            }

            this.placeTarget.appendChild(option);
        });

    }

    get selectedRegionId() {
        return this.targets.find("region").value
    }

    get selectedMunicipalityId() {
        return this.targets.find("municipality").value
    }







    // For Address by ClientNationalId fields. Was stupid to c/p, but faster

    onRegionByIdChange(ev) {
        console.log("REGION BY ID")
        this.getMunicipalitiesByRegionById(this.selectedRegionByIdId)
    }

    onMunicipalityByIdChange() {
        console.log("MUNICIPALITY BY ID")

        this.getPlacesByMunicipalityById(this.selectedMunicipalityByIdId);
    }

    getMunicipalitiesByRegionById(regionId) {
        let that = this;

        if(!regionId) {
            return;
        }

        $.ajax({
            type: "GET",
            url: "/ekatte/municipalities-by-region/" + regionId,
            success: function (data) {

                if (data.success) {
                    that.updateMunicipalityByIdDropdown(data.payload);

                    let munId = Object.values(data.payload)[0].id;
                    let prevSelectedMunicipalityId = that.targets.find("municipalityById").dataset.municipalityId;   // при грешка напр и рефреш на страницата да сетне предишно избраната община
                    if(prevSelectedMunicipalityId) {
                        munId = prevSelectedMunicipalityId;
                        that.targets.find("municipalityById").dataset.municipalityId = "";
                    }
                    that.getPlacesByMunicipalityById(munId)
                }
            }
        })
    }

    getPlacesByMunicipalityById(municipalityId) {
        let that = this;

        $.ajax({
            type: "GET",
            url: "/ekatte/places-by-municipality/" + municipalityId,
            success: function (data) {
                if (data.success) {
                    that.updatePlaceByIdDropdown(data.payload);
                }
            }
        })
    }

    updateMunicipalityByIdDropdown(data) {

        this.municipalityByIdTarget.innerHTML = "";

        Object.values(data).forEach((m) => {
            const option = document.createElement("option");
            option.value = m.id;
            option.innerHTML = m.name;

            let prevSelectedMunicipalityId = this.targets.find("municipalityById").dataset.municipalityId;   // при грешка напр и рефреш на страницата да сетне предишно избраната община
            if(prevSelectedMunicipalityId && prevSelectedMunicipalityId == m.id) {
                option.selected = true;
                // this.targets.find("municipality").dataset.municipalityId = "";
            }

            this.municipalityByIdTarget.appendChild(option);
        });

    }

    updatePlaceByIdDropdown(data) {
        this.placeByIdTarget.innerHTML = "";

        Object.values(data).forEach((m) => {
            const option = document.createElement("option");
            option.value = m.id;
            option.innerHTML = m.name;

            let prevSelectedPlaceId = this.targets.find("placeById").dataset.placeId;   // при грешка напр и рефреш на страницата да сетне предишно избраната община
            console.log("PLACE ", prevSelectedPlaceId)
            if(prevSelectedPlaceId && prevSelectedPlaceId == m.id) {
                option.selected = true;
                // this.targets.find("place").dataset.placeId = "";
            }

            this.placeByIdTarget.appendChild(option);
        });

    }

    get selectedRegionByIdId() {
        return this.targets.find("regionById").value
    }

    get selectedMunicipalityByIdId() {
        return this.targets.find("municipalityById").value
    }



}