import {Controller} from "stimulus";

export default class extends Controller {
    connect(){
        EventManager.listen('master-field-changed', data => {
            if (data.propertyName === this.propertyName) {
                if (data.value === 'Bank account') {
                    this.element.classList.remove('d-none');
                } else {
                    this.element.classList.add('d-none');
                    this.element.querySelectorAll('select').forEach(element => element.value = '');
                }
            }
        })
    }

    get propertyName() {
        return this.element.dataset.propertyName;
    }
}