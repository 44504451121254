import {Controller} from "stimulus";

export default class extends Controller {
    change() {
        let label = this.element.parentNode.querySelector('.custom-file-label');

        if (this.element.files.length > 0) {
            label.innerHTML = "<span>" + this.element.files[0].name + "</span>";
        } else {
            label.innerHTML = "<span></span>";
        }
    }
}