import {Controller} from "stimulus";
import {initializeTestimonialVideosCarousel} from "../../custom";

/* stimulusFetch: 'lazy' */
export default class extends Controller {
    static values = {
        url: String
    }

    loaded = false;

    connect() {
        const controller = this;

        window.addEventListener('scroll', async function () {
            const elementPosition = controller.element.getBoundingClientRect().top;

            if (elementPosition < 1000 && controller.loaded === false) {
                controller.loaded = true;

                const response = await fetch(controller.urlValue, {
                    method: "POST"
                });

                const {output} = await response.json();
                controller.element.innerHTML = output;

                initializeTestimonialVideosCarousel();
            }
        })
    }
}